import React from 'react'
import PropTypes from 'prop-types';


export const CirclePath = ( props ) => {
  const {x,y, clockwise, fromInside, toolDiameter} = props;
  const r = !toolDiameter ? props.r : props.r + (fromInside ? -toolDiameter/2 : toolDiameter/2)   
  // entryOffset should be absolute
  const entryOffset = fromInside ? +props.entryOffset : -props.entryOffset   
  let path = ""      
  // drilling entry/start point
  // from inside of circle - left to right side of hole
  // from outside - right to right side of hole - by offset sign change above
  path += `M${+x+r-entryOffset},${+y}`
  // go to arc start 
  path += `h${+entryOffset}`
  // CCW or CW circle in 2 parts/arcs
  const ccw = +!clockwise
  const diam = 2*r
  path +=`a${+r},${+r} 0 0,${ccw} ${-diam},0`
  path +=`a${+r},${+r} 0 0,${ccw} ${+diam},0`
  return (                  
    <path id="cut hole"
      key={'_'+x} 
      d={path}
    />
  ) 
}


CirclePath.propTypes = {
  x: PropTypes.number,
  y: PropTypes.number,
  r: PropTypes.number,
}


CirclePath.defaultProps = {
  clockwise: false,
  fromInside: true,
  entryOffset: 0,            
  toolDiameter: 0, 
}

                        
/*
module.exports = {
  CirclePath: CirclePath,
}
*/