/* eslint-disable react/prop-types */
import React, { Fragment } from "react";
import PropTypes from "prop-types";
import {
  // useTranslation,
  withTranslation,
  // WithTranslation,
} from "react-i18next";
import { withStyles, withTheme } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
//import TextField from 'material-ui/TextField';
//import Select from 'material-ui/Select';
import Switch from "@material-ui/core/Switch";
//import { InputLabel } from 'material-ui/Input';
//import { MenuItem } from 'material-ui/Menu';
import { FormLabel } from "@material-ui/core";
import { FormGroup } from "@material-ui/core";
import { FormControl } from "@material-ui/core";
import { FormHelperText } from "@material-ui/core";

import QRD2DDataTable from "./QRD2DDataTable";

import "./QRDConverter.css";

const styles = (theme) => ({
  root: {
    width: "100%",
    // maxWidth: "36ch",
    backgroundColor: theme.palette.background.paper,
  },
  leftColumn: {
    width: 100,
  },
  blockFormTitle: {
    marginBottom: 6,
    marginTop: 20,
  },
});

class QRD2DViewer extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      showTable: false,
      showHeights: false,
    };
  }

  handleBoolChange = (name) => (event) => {
    this.setState({ [name]: event.target.checked });
  };

  render() {
    const {
      rowSelected,
      columnSelected,

      params,
      classes,
      api,
      t,
    } = this.props;

    const {
      //      keepSquared,
      columns,
      rows,
      //      keepCentered,
      //      columnOffset,
      //      rowOffset,
      //      negative,
      useHeight,
      minHeight,
      maxHeight,
      useRounding,
    } = params;

    const { primes, squares } = this.props.primes;
    //    const squares = this.squares;
    const { showTable, showHeights } = this.state;

    //    const dataset = this.props.calculated.dataset
    const { dataset, meta } = this.props.calculated;
    const currentStamp = this.props.calculated.stamp;

    //    const heights = api.getHeights().

    const sx = {
      blockFormTitle: {
        marginBottom: 6,
        marginTop: 20,
      },
      switchLabel: {
        //        marginTop: 10,
        //        marginBottom: 8,
        //        position: 'relative',
        //        top: 13,
      },
      infoColumn: {
        display: "inline-block",
        verticalAlign: "middle",
        maxWidth: 250,
      },
      leftColumn: {
        width: 100,
      },
      wideColumn: {
        maxWidth: 200,
      },
      cell: {
        display: "inline",
        padding: 12,
      },
      cellScrolled: {
        fontSize: 11,
        overflowX: "auto",
        overflowY: "auto",
        display: "inline-block",
        verticalAlign: "middle",
        maxWidth: 800,
        maxHeight: 600,
        padding: 4,
      },
    };

    return (
      <div>
        <Typography variant="h5" style={{ ...sx.blockFormTitle }}>
          {t("data.title", "Data view")}
        </Typography>

        <FormControl
          className={classes.formControl}
          style={{ ...sx.leftColumn }}
        >
          <FormLabel component="legend" style={{ ...sx.switchLabel }}>
            {t("data.showTable", "Show table")}
          </FormLabel>
          <FormGroup>
            <Switch
              checked={showTable}
              onChange={this.handleBoolChange("showTable")}
            />
          </FormGroup>
          <FormHelperText>
            {t("data.generatedData", "Generated data")}
          </FormHelperText>
        </FormControl>

        {showTable && (
          <Fragment>
            <FormControl className={classes.formControl}>
              <FormLabel component="legend" style={{ ...sx.switchLabel }}>
                {t("data.useHeights", "Use heights")}
              </FormLabel>
              <FormGroup>
                <Switch
                  checked={showHeights}
                  onChange={this.handleBoolChange("showHeights")}
                />
              </FormGroup>
              <FormHelperText>
                {showHeights
                  ? t("data.heightsMm", "Heights in mm")
                  : t("data.depthUnits", "Depth units")}
              </FormHelperText>
            </FormControl>
            {showHeights && (
              <div style={{ ...sx.leftColumn, ...sx.infoColumn }}>
                <b>
                  {t("data.scalingToRange", {
                    minValue: minHeight,
                    maxValue: maxHeight,
                  })}
                </b>
              </div>
            )}

            <QRD2DDataTable
              dataset={dataset}
              meta={meta}
              stamp={currentStamp}
              rows={rows}
              columns={columns}
              useColumnsAsRows={false}
              minHeight={minHeight}
              maxHeight={maxHeight}
              useRounding={useRounding}
              useHeight={useHeight}
              showHeights={showHeights}
              rowSelected={rowSelected}
              columnSelected={columnSelected}
              onRowClick={api.handleRowSelect}
              onColumnClick={api.handleColumnSelect}
              primes={primes}
              squares={squares}
              api={api}
            />
          </Fragment>
        )}
      </div>
    );
  }
}

QRD2DViewer.propTypes = {
  classes: PropTypes.object.isRequired,
  //  setResult: PropTypes.func.isRequired,
};

// export default withStyles()(withTheme(QRD2DViewer));
export default withTranslation("diffuser")(
  withTheme(withStyles(styles)(QRD2DViewer))
);
