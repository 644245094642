import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
//import { withStyles, withTheme } from 'material-ui/styles'
//import Typography from 'material-ui/Typography';
//import TextField from 'material-ui/TextField';
//import Select from 'material-ui/Select';                      
//import Switch from 'material-ui/Switch';
//import { InputLabel } from 'material-ui/Input';
//import { MenuItem } from 'material-ui/Menu';
//import { FormLabel, FormGroup, FormControl, FormHelperText } from 'material-ui/Form';
//import QRD2DDataTable from './QRD2DDataTable'

//import { getPrimesLimit } from '../data/prime';         

import './BOM.css';

export const ElementsByHeight = (props) => {
  const {
    bomElements,            
    useHeight,
    useRounding, 
    heights,
//    api,
  } = props
  
  return (
    <div>
      <div>    
        <b>Amount of elements by height</b>:
      </div>    
      {Object.keys(bomElements).sort(function(a,b) { return a - b;}).map( (height) => {
        let amount = bomElements[height]    
        let heightText = !useHeight ?
          height+" units"
          : useRounding ?
            heights[ height ] + " mm"
            : heights[ height ].toFixed(2) + " mm"
        return (
          <div key={height}>{amount} element(s) with height {heightText}</div>
        )}
      )}
    </div>
  )
}
ElementsByHeight.propTypes = {
  bomElements: PropTypes.object.isRequired,
  useHeight: PropTypes.bool.isRequired,
  useRounding: PropTypes.bool.isRequired,
  heights: PropTypes.array.isRequired,
};


export const TotalBlocksHeight = (props) => {
  const {
    bomElements,            
//    useHeight,
    useRounding, 
//    heights,
//    api,
  } = props                                          
  const { rows, columns } = props.meta.params
  let heights = props.heights                           
  
  // inconsistent state - recalculations in progress?
//  debugger                  
  if( heights.length !== props.meta.period ) return null
  
  if( !useRounding ) {
    heights = heights.map( v => parseFloat(v.toFixed(2)) )
  }                                       
//  let totalHeight = 0;
//  Object.keys(bomElements).map( val => {totalHeight += heights[val]*bomElements[val]; return 1;} )
  // bomElements mapping object ( keys=height values=amount of this heights )
  const totalHeight = Object.keys(bomElements).reduce( (acc,height) => acc+heights[height]*bomElements[height], 0)
  let totalElements = bomElements[0] ? ` (${rows*columns-bomElements[0]})` :'';
//  console.log("totalHeight",bomElements, heights, totalHeight)
  return (               
    <div >
      Total length: {totalHeight/1000} m in {rows*columns}{totalElements} elements. 
    </div>
  )
}
TotalBlocksHeight.propTypes = {
  bomElements: PropTypes.object.isRequired,
  meta: PropTypes.object.isRequired,
}


export const TotalBlocksVolume = (props) => {
  const blockWidth = props.blockWidth  
  const totalHeight = props.meta.stats.totalHeight
  const volume = blockWidth * blockWidth * totalHeight / 1e9   
  
  return (               
    <Fragment>
    {1&& //!!useHeight &&       
    <div >
      BAD___ Total volume: {volume} m<sup>3</sup>. 
    </div>
    }
    </Fragment>
  )
}
TotalBlocksVolume.propTypes = {
  meta: PropTypes.object.isRequired,
  blockWidth: PropTypes.number.isRequired, 
}





const Equivalents = (props) => {
  const {   
    rows,
          
    dataset,            
    meta,   
    showHeights,
    useRounding, 
    
    primes,
    formatNumber,
    api,
  } = props
//  const period=meta.period
  const stripped = !!meta.margin ? `  margins(${meta.margin}), ` : ', '
  const squares = primes.squares;
  const commonHeights = api.getHeights()
  let heights = commonHeights.dataset 
  if( !useRounding ) {
    heights = heights.map( v => v.toFixed(2) )
  } 
  
  
  return (
    <div>
      <div className="mono lefty-on-right">
        <div className="equivalents">    
        {dataset.map( (n, i) => { let info=meta.rowData[i];
//          let title = "N"+ period +'+'+ squares[info.verticalOffset]%period +","+ info.horizontalOffset +stripped+"  max: "+info.maxValue
          let title = "N"+ info.period +'+'+ squares[info.verticalOffset]%info.period +","+ 
            info.horizontalOffset +stripped+"  max: "+info.maxValue + (showHeights? ` (${heights[info.maxValue]} mm)`:'')
            
          return ( 
            <span key={i}>Row {formatNumber(rows-i)}  ... equivalent to 1D panel {title} 
              <br />{n.map(v=>formatNumber( showHeights ? heights[v] : v )).join(',\xa0')}
              <br />
            </span>
        ); } )}
        </div>
      </div>
    </div>
  )
}

Equivalents.propTypes = {
  rows: PropTypes.number.isRequired,

  dataset: PropTypes.array.isRequired,
  meta: PropTypes.object.isRequired,

  primes: PropTypes.object.isRequired,
  formatNumber: PropTypes.func.isRequired,
};



export const QRDStats = (props) => {
  const {
    calculated,            

//    blockWidth,    
    showHeights,

    api,
    primes,
  } = props
  const { dataset, meta } = calculated 
  const currentStamp = calculated.stamp 
  const periodLength = meta.period 
  const {     
    rows, 
//    useHeight,
    useRounding,
  } = meta.params
   
  const bomElements = !!meta.stats ? meta.stats.bomElements : [] 

  const commonHeights = api.getHeights()
  const heights = commonHeights.dataset
  const { maxHeight } = commonHeights.meta.params  
                 
  const maxNumLength = ( ''+(showHeights?maxHeight:periodLength) ).length + (!useRounding&&showHeights) * 3     
//  console.log("maxNumLength",maxNumLength)
//  const formatNumber = api.formatNumberLeading
  const formatNumber = (v) => api.formatNumberLeading(v,maxNumLength,'\xa0')
  
  
                        
  
  return (
    <div className="billOfMaterial" >
      <div className="bom-title">
        Configuration <b>{currentStamp}</b><br />
      </div>

      <Equivalents
        rows={rows}
      
        dataset={dataset}            
        meta={meta}
        showHeights={showHeights}
        useRounding={useRounding} 

        
        primes={primes}
        formatNumber={formatNumber}
        api={api}
        heights={heights}
      />

      <ElementsByHeight           
        bomElements={bomElements}
        useHeight={showHeights}
        useRounding={useRounding}
        heights={heights}
      />         
                      
    </div>
  )
}

QRDStats.propTypes = {
  calculated: PropTypes.object.isRequired,
//  periodLength: PropTypes.number.isRequired,

  primes: PropTypes.object.isRequired,
};

/*
module.exports = {
  QRDStats: QRDStats,
  Equivalents: Equivalents,
  ElementsByHeight: ElementsByHeight,
  TotalBlocksHeight: TotalBlocksHeight,
  TotalBlocksVolume: TotalBlocksVolume,
}
*/