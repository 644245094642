const pl = require('./pl');
//const de = require('./de');
const en = require('./en');


module.exports = {
  pl:pl,
//  de:de,
  en:en,
};
