import React, { Component } from "react";
import PropTypes from "prop-types";
import {
  // useTranslation,
  withTranslation,
  // WithTranslation,
} from "react-i18next";

//import ReactTranslated from 'react-translated'
//import translation from '../data/translation'
//import i18n from '../data/i18n';
import Typography from "@material-ui/core/Typography";
import { withStyles } from "@material-ui/core/styles";
import withRoot from "../withRoot";

import LangSwitcher from "./LangSwitcher";

//import ObjectList from './ObjectList';
import Comment from "./Comment";
import QRDConverter from "./QRDConverter";
//import logo from './logo.svg';
import "./App.css";

const styles = (theme) => ({
  root: {
    textAlign: "center",
    fontSize: 26,
    paddingTop: theme.spacing(20),
  },
  textFieldRoot: {
    padding: 0,
    "label + &": {
      marginTop: theme.spacing(3),
    },
  },
  textFieldInput: {
    borderRadius: 4,
    backgroundColor: theme.palette.common.white,
    border: "1px solid #ced4da",
    fontSize: 26,
    padding: "10px 12px",
    width: "calc(100% - 24px)",
    transition: theme.transitions.create(["border-color", "box-shadow"]),
    "&:focus": {
      borderColor: "#80bdff",
      boxShadow: "0 0 0 0.2rem rgba(0,123,255,.25)",
    },
  },
  textFieldFormLabel: {
    fontSize: 18,
  },
});

class App extends Component {
  constructor(props) {
    super(props);
    window.showComments = false;
    this.state = {
      showComments: window.showComments,
    };
  }
  // shouldComponentUpdate = (nextProps, nextState) => {
  //   console.log("__App, SCU");
  //   if (nextState.showComments !== this.state.showComments) {
  //     console.log("__App, SCU, showComments", nextState.showComments);
  //     return true;
  //   }
  //   return false;
  // };

  // global variable but used in state for forcing render
  handleChangeComments = (e) => {
    e.preventDefault();
    window.showComments = !window.showComments;
    console.log("__App, comments:", window.showComments);
    //    this.props.lng = lang;
    //    i18n.changeLanguage(lang);
    this.setState({
      showComments: window.showComments,
    });
  };

  CommentsSwitcher = () => {
    const sx = {
      commentsSwitcher: {
        display: "inline-block",
        margin: "6px",
        color: "red",
        cursor: "pointer",
      },
      langBtn: {
        display: "inline-block",
      },
    };

    const { t } = this.props;

    return (
      <div style={{ ...sx.commentsSwitcher }}>
        <div
          style={{ ...sx.langBtn }}
          onClick={this.handleChangeComments.bind(this)}
        >
          {t("commentsDabug", "COMMENTS AND DEBUG DATA")}:{" "}
          {this.state.showComments
            ? t("visible", "VISIBLE")
            : t("hidden", "HIDDEN")}
        </div>
        <LangSwitcher />
      </div>
    );
  };

  render() {
    const { t } = this.props;
    return (
      <div className="App">
        {this.CommentsSwitcher()}
        <Comment>
          {t("POC", "Proof Of Concept - evaluating miscelanous ideas")}
        </Comment>
        <Typography variant="h4" gutterBottom>
          {t("title", "QRD-type acoustic diffuser calculator")}
        </Typography>

        <QRDConverter />
      </div>
    );
  }
}

App.propTypes = {
  t: PropTypes.func,
};

export default withTranslation()(withRoot(withStyles(styles)(App)));
