import React from "react";
import PropTypes from "prop-types";
import { MuiThemeProvider } from "@material-ui/core/styles";
import CssBaseline from "@material-ui/core/CssBaseline";
import getPageContext from "./getPageContext";

function withRoot(Component) {
  class WithRoot extends React.Component {
    // componentDidMount() {
    //   // Remove the server-side injected CSS.
    //   const jssStyles = document.querySelector('#jss-server-side');
    //   if (jssStyles && jssStyles.parentNode) {
    //     jssStyles.parentNode.removeChild(jssStyles);
    //   }
    // }

    pageContext = null;

    render() {
      this.pageContext = this.props.pageContext || getPageContext();
      // MuiThemeProvider makes the theme available down the React tree thanks to React context.
      //          theme={this.pageContext.theme}
      return (
        <MuiThemeProvider theme={this.pageContext.theme}>
          {/* Reboot kickstart an elegant, consistent, and simple baseline to build upon. */}
          <CssBaseline />
          <Component {...this.props} />
        </MuiThemeProvider>
      );
    }
  }

  WithRoot.propTypes = {
    pageContext: PropTypes.object,
  };

  return WithRoot;
}

export default withRoot;
