/* eslint-disable react/prop-types */
import React from "react";
import PropTypes from "prop-types";
//import { withStyles, withTheme } from 'material-ui/styles'
//import Typography from 'material-ui/Typography';
//import TextField from 'material-ui/TextField';
//import Select from 'material-ui/Select';
//import Switch from 'material-ui/Switch';
//import { InputLabel } from 'material-ui/Input';
//import { MenuItem } from 'material-ui/Menu';
//import { FormLabel, FormGroup, FormControl, FormHelperText } from 'material-ui/Form';

//import { getPrimesLimit } from '../data/prime';

import "./QRD2DDataTable.css";

class QRD2DDataTable extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      stamp: "", // different source data indicator
      useColumnsAsRows: props.useColumnsAsRows,
      useHalfData: props.useHalfData,

      // local data
      rowData: [],
      rowMeta: [],
    };
    this.fillRows = this.fillRows.bind(this);
  }

  fillRows() {
    const {
      //      rows,
      //      columns,
      dataset,
      meta,
    } = this.props;
    let rowData = [];
    let rowMeta = [];
    dataset.forEach((n, i) => {
      rowData.push(n);
      rowMeta.push(meta.rowData[i]);
    });
    // for visualisations - rows counted from bottom
    this.setState({
      rowData: rowData.reverse(),
      rowMeta: rowMeta.reverse(),
    });
  }

  fillRowsFromColumns() {
    const {
      //      rows,
      columns,
      dataset,
      //      meta,
    } = this.props;
    let rowData = [];
    let rowMeta = [];
    for (let j = 0; j < columns; j++) {
      let row = [];
      let maxValue = 0;
      dataset
        .slice()
        .reverse()
        .forEach((n, i) => {
          row.push(n[j]);
          if (n[j] > maxValue) maxValue = n[j];
        });
      rowData.push(row);
      // TODO proper data
      rowMeta.push({
        period: 1,
        horizontalOffset: 0,
        verticalOffset: 0,
        maxValue: maxValue,
      });
    }
    // for visualisations - rows counted from bottom
    this.setState({
      rowData: rowData.reverse(),
      rowMeta: rowMeta.reverse(),
    });
    console.log("TAB fillRowsFromColumns ", rowData, rowMeta);
  }

  componentDidMount() {
    console.log("TAB CDM");
    this.fillRows();
  }

  /*
  shouldComponentUpdate = (nextProps, nextState) => {
    console.log('TAB SCU')
    
    let result = false
    // whole data
    if ( nextProps.stamp !== this.props.stamp ) {
      console.log("TAB SCU nextState stamp",this.state.stamp, " <> ",nextProps.stamp, " <> ",nextState.stamp )
//      this.setState({ stamp: nextProps.stamp });
      result = true
    }
    // orientation changed
    if ( nextProps.useColumnsAsRows !== this.props.useColumnsAsRows ) {  
//      this.setState({ useColumnsAsRows: this.props.useColumnsAsRows });
      result = true
    }


    if ( nextProps.calculated !== this.props.calculated ) result = true

    if ( nextProps.rowSelected !== this.props.rowSelected ) result = true
    if ( nextProps.columnSelected !== this.props.columnSelected ) result = true

    if ( this.state.stamp !== nextProps.stamp ) {
      console.log("TAB SCU nextState stamp",this.state.stamp, " <> ",nextProps.stamp, " <> ",nextState.stamp )
//      this.setState({ stamp: nextProps.stamp });
      result = true
    }

    return result
  }
*/
  componentDidUpdate() {
    //    console.log("TAB CDU");
    let needRefilling = false;
    if (this.state.stamp !== this.props.stamp) {
      this.setState({ stamp: this.props.stamp });
      needRefilling = true;
    }
    if (this.state.useColumnsAsRows !== this.props.useColumnsAsRows) {
      this.setState({ useColumnsAsRows: this.props.useColumnsAsRows });
      needRefilling = true;
    }

    if (this.state.useHalfData !== this.props.useHalfData) {
      this.setState({ useHalfData: this.props.useHalfData });
      needRefilling = true;
    }

    if (needRefilling) {
      if (this.props.useColumnsAsRows) {
        //        console.log("TAB CDU NOT IMPLEMENTED");
        this.fillRowsFromColumns();
      } else {
        //        console.log("TAB CDU fillRows");
        this.fillRows();
      }
    }
    /*    
    if( this.state.rowSelected !== this.props.rowSelected ) {
      this.setState({ rowSelected: this.props.rowSelected });
    }
    if( this.state.columnSelected !== this.props.columnSelected ) {
      this.setState({ columnSelected: this.props.columnSelected });
    }
*/
    //    if ( nextProps.useColumnsAsRows !== this.props.useColumnsAsRows ) {
    //    }
  }

  render() {
    const {
      //      minHeight,
      //      maxHeight,
      //      useRounding,
      //      useHeight,
      useColumnsAsRows,

      showHeights,

      //      calculated,
      //      meta,
      stamp,

      //      handleUseColumnsAsRows,

      //      classes,  // theme
      squares,
      api,
    } = this.props;
    //    const squares = this.props.primes.squares;

    const commonHeights = api.getHeights();
    const heights = commonHeights.dataset;
    const { minHeight, maxHeight } = commonHeights.meta.params;

    //    const {  totalHeight } = this.props.calculated

    const { rowData, rowMeta } = this.state;

    // row <> column flipping
    const onRowClick = useColumnsAsRows
      ? this.props.onColumnClick
      : this.props.onRowClick;
    const rows = useColumnsAsRows ? this.props.columns : this.props.rows;
    const columns = useColumnsAsRows ? this.props.rows : this.props.columns;

    // periodLength can be out of sync
    const maxLevels = rows > columns ? rows : columns;

    const rowSelected = useColumnsAsRows
      ? this.props.columnSelected
      : this.props.rowSelected;
    /*      
    const sx = {
      blockFormTitle: {
        marginBottom: 6,        
        marginTop: 20,        
      },
      switchLabel: {
//        marginTop: 10,
//        marginBottom: 8,        
//        position: 'relative',
//        top: 13,
      },
      infoColumn: {
        display: 'inline-block',
        verticalAlign: 'middle',
        maxWidth: 250,        
      },
      leftColumn: {
        width: 100,
      },
      wideColumn: {
        maxWidth: 200,        
      },
      cell: {
        display: 'inline',
        padding: 12,
      },
      cellScrolled: {
        fontSize: 11,
        overflowX: 'auto',
        overflowY: 'auto',
        display: 'inline-block',
        verticalAlign: 'middle',
        maxWidth: 800,
        maxHeight: 600,                
        padding: 4,
      },
    }
*/
    //debugger
    // 'alphas' between base and limit as 'procents'
    const colorStep = (n, limit, base = 20) => {
      let unit = showHeights
        ? (limit - base) / (maxHeight - minHeight + 1)
        : (limit - base) / (maxLevels - 1);
      let value = (n - (showHeights ? minHeight : 0)) * unit + base;
      return (value / 100).toFixed(2);
    };

    const hideScrolling =
      25 * rows > 590 ? { overflowY: "auto" } : { overflowY: "hidden" };

    //    console.log("TAB render")
    //    console.log(useHeight, maxLevels)

    return (
      <div key={stamp} className="dataTable" style={{ ...hideScrolling }}>
        {rowData.map((n, i) => {
          let info = rowMeta[i];
          let classes = i === rows - rowSelected ? "dataRowSelected" : ""; //"dataRow dataRowSelected" : "dataRow"
          let sx = { width: columns * 25 };
          let title =
            "N" +
            info.period +
            "+" +
            (squares[info.verticalOffset] % info.period) +
            "," +
            info.horizontalOffset +
            "  max: " +
            info.maxValue +
            (showHeights ? ` (${heights[info.maxValue]} mm)` : "");
          //            console.log("TAB calculated",rowData)
          return (
            <div
              key={i}
              className={classes}
              title={title}
              onClick={(e) => onRowClick(rows - i)}
              style={{ ...sx }}
            >
              {n.map((m, j) => (
                <div
                  key={i + "_" + j}
                  cn={/*className="dataCell"*/ "cn"}
                  style={{
                    backgroundColor:
                      "rgba(80, 144, 152," +
                      colorStep(showHeights ? heights[m] : m, 60) +
                      ")",
                  }}
                >
                  {showHeights ? heights[m] : m}
                </div>
              ))}
            </div>
          );
        })}
      </div>
    );
  }
}

QRD2DDataTable.propTypes = {
  squares: PropTypes.array.isRequired,
};

export default QRD2DDataTable;
