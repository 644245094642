/* eslint-disable no-underscore-dangle */

import { SheetsRegistry } from "jss";
import {
  createMuiTheme,
  createGenerateClassName,
} from "@material-ui/core/styles";
// import purple from "@material-ui/core/colors/purple";
// import green from "@material-ui/core/colors/green";
import cyan from "@material-ui/core/colors/cyan";
import blue from "@material-ui/core/colors/blue";

// A theme with custom primary and secondary color.
// It's optional.
const theme = createMuiTheme({
  palette: {
    primary: {
      light: cyan[300],
      main: cyan[500],
      dark: cyan[700],
      contrastText: "#fff",
    },
    secondary: {
      light: blue[300],
      main: blue[500],
      dark: blue[700],
      contrastText: "#fff",
    },
  },
  overrides: {
    MuiInput: {
      // Name of the styleSheet
      root: {
        // Name of the rule
        //        background: 'linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)',
        fontSize: 18,
      },
      formControl: {
        "label + &": {
          marginTop: 1,
        },
      },
    },
    MuiInputLabel: {
      // Name of the styleSheet
      root: {
        fontSize: 16,
      },
      formControl: {
        width: "-moz-max-content",
        position: "relative",
      },
      shrink: {
        transformOrigin: "bottom left",
        position: "relative",
        // maxWidth: "100%",
        maxWidth: 150,
      },
    },
    MuiButton: {
      // Name of the styleSheet
      root: {
        fontSize: 16,
      },
      label: {
        fontSize: 18,
        //        fontWeight: 'bold',
      },
      fullWidth: {
        border: "1px solid lightgray",
      },
    },

    MuiFormControl: {
      // Name of the styleSheet
      root: {
        marginLeft: 18,
        marginRight: 18,
        marginBottom: 16,
        maxWidth: 140,
        verticalAlign: "text-bottom",
      },
      fullWidth: {
        maxWidth: "unset",
      },
    },
    MuiTypography: {
      // Name of the styleSheet
      root: {
        marginTop: 8,
      },
    },
    MuiMenuItem: {
      // Name of the styleSheet
      root: {
        fontSize: 18,
      },
    },
    // MuiAppBar: {
    //   // Name of the styleSheet
    //   root: {
    //     fontSize: "1.1em",
    //   },
    // },
    MuiTab: {
      // Name of the styleSheet
      root: {
        fontSize: "1.2em",
        minWidth: "75px",
        paddingLeft: "1em",
        paddingRight: "1em",

        "@media (min-width:600px)": {
          minWidth: "85px",
        },
      },
    },
    MuiFormHelperText: {
      // Name of the styleSheet
      root: {
        fontSize: 11,
        marginTop: 3,
        lineHeight: 1.1,
      },
    },
    MuiFormLabel: {
      // Name of the styleSheet
      root: {
        fontSize: 12,
      },
    },
    MuiIconButton: {
      root: {
        height: 36,
      },
    },
  },

  MuiSwitch: {
    formControl: {
      //      marginTop: 50,
    },
  },
});

function createPageContext() {
  return {
    theme,
    // This is needed in order to deduplicate the injection of CSS in the page.
    sheetsManager: new Map(),
    // This is needed in order to inject the critical CSS.
    sheetsRegistry: new SheetsRegistry(),
    // The standard class name generator.
    generateClassName: createGenerateClassName(),
  };
}

export default function getPageContext() {
  // Make sure to create a new context for every server-side request so that data
  // isn't shared between connections (which would be bad).
  if (!process.browser) {
    return createPageContext();
  }

  // Reuse context on the client-side.
  if (!global.__INIT_MATERIAL_UI__) {
    global.__INIT_MATERIAL_UI__ = createPageContext();
  }

  return global.__INIT_MATERIAL_UI__;
}
