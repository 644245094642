/* eslint-disable react/prop-types */
import React, { Component } from "react";
import * as THREE from "three";
//const OrbitControls = require('three-orbit-controls')(THREE);
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls.js";

class View3DThree extends Component {
  constructor(props) {
    super(props);

    // default
    this.blockColor = 132 * 256 * 256 + 82 * 256 + 45;

    //    this.getColor();

    // required by OrbitControls
    //    this.renderScene = this.renderScene.bind(this)
  }

  getColor = () => {
    if (this.props.blockColor) {
      this.blockColor = Math.round(
        this.props.blockColor.r * 256 * 256 +
          this.props.blockColor.g * 256 +
          this.props.blockColor.b
      );
      // .toFixed(0)
      // .toString(16);
    }
    return this.blockColor;
  };

  buildMaterial = () => {
    this.getColor();
    console.log("this.blockColor", this.props.blockColor, this.blockColor);
    const material = new THREE.MeshPhongMaterial({
      // color: this.blockColor,
      color: this.blockColor,
      // color: this.getColor(),
      specular: 0x333333,
      shininess: 40,
      dithering: true,
      wireframe: false,
    });
    // if (this.props.blockColor) {
    //   material.color.r = this.props.blockColor.r;
    //   material.color.g = this.props.blockColor.g;
    //   material.color.b = this.props.blockColor.b;
    // }
    this.material = material;
  };

  bar = (xPos, yPos, barWidth, barHeight) => {
    // universal methods ..  for readibility
    // size
    const geometry = (boxWidth, boxHeight, boxDepth) =>
      new THREE.BoxGeometry(boxWidth, boxHeight, boxDepth);
    // object
    const box = (x, y, z) => new THREE.Mesh(geometry(x, y, z), this.material);

    // positioned on XZ plane - app specific orientation
    let bar = box(barWidth, barHeight, barWidth); // bar squared on bottom

    bar.position.set(xPos, barHeight / 2, yPos); // y on depth; bottom-aligned
    return bar;
  };

  // slightly optimized version
  bar2 = (xPos, yPos, barWidth, barHeight) => {
    let box = new THREE.Mesh(
      new THREE.BoxGeometry(barWidth, barHeight, barWidth),
      this.material
    );
    box.position.set(xPos, barHeight / 2, yPos); // y on depth; bottom-aligned
    return box;
  };

  buildModel = () => {
    const {
      blockPoints,
      //    rowParams,
      blockWidth,
      blockSpace,
      usingHeights,
      heights,
    } = this.props;

    const scene = this.scene;
    const bar = this.bar2; // switchable method

    const space = blockWidth + blockSpace;
    const rows = blockPoints.length;
    const columns = rows ? blockPoints[0].length : 0;
    const dx = -(columns * space) / 2; // x -left +right
    const dy = (rows * space) / 2; // z -back +front

    this.buildMaterial();

    let group = new THREE.Group();
    if (usingHeights) {
      blockPoints.map((row, i) =>
        row.map((barHeight, j) => {
          return group.add(
            bar(dx + j * space, dy - i * space, blockWidth, heights[barHeight])
          );
        })
      );
    } else {
      blockPoints.map((row, i) =>
        row.map((barHeight, j) => {
          return !barHeight
            ? false
            : group.add(
                bar(dx + j * space, dy - i * space, blockWidth, barHeight)
              );
        })
      );
    }
    scene.add(group);
    this.group = group;
  };

  initScene = () => {
    const width = this.props.viewWidth;
    const height = this.props.viewHeight;

    const scene = new THREE.Scene();
    const camera = new THREE.PerspectiveCamera(75, width / height, 0.1, 4000);
    const renderer = new THREE.WebGLRenderer({ antialias: true });
    renderer.setPixelRatio(window.devicePixelRatio);

    scene.background = new THREE.Color(0xf0f0f0);
    //    scene.add( new THREE.AmbientLight( 0x505050 ) );

    // scene.add(new THREE.AmbientLight(0xffffff, 0.5));
    scene.add(new THREE.AmbientLight(0xdddddd, 0.9));

    let light = new THREE.SpotLight(0xffffff, 0.2);
    light.position.set(0, 2000, 2000);
    light.target.position.set(0, 0, 0);
    light.target.updateMatrixWorld();
    light.castShadow = true;
    scene.add(light);

    light = new THREE.SpotLight(0xffffff, 0.2);
    light.position.set(800, 2000, 800);
    light.target.position.set(0, 0, 0);
    light.target.updateMatrixWorld();
    light.castShadow = true;
    scene.add(light);

    light = new THREE.SpotLight(0xffffff, 0.2);
    light.position.set(400, 2000, -400);
    light.target.position.set(0, 0, 0);
    light.target.updateMatrixWorld();
    light.castShadow = true;
    scene.add(light);

    /*
				var spotLight = new THREE.SpotLight( 0xffffff, 1 );
				spotLight.position.set( 15, 40, 35 );
				spotLight.angle = Math.PI / 4;
				spotLight.penumbra = 0.05;
				spotLight.decay = 2;
				spotLight.distance = 2000;
				spotLight.castShadow = true;
				spotLight.shadow.mapSize.width = 1024;
				spotLight.shadow.mapSize.height = 1024;
				spotLight.shadow.camera.near = 10;
				spotLight.shadow.camera.far = 2000;
				scene.add( spotLight );               
*/

    camera.position.set(-20, 140, 220);

    renderer.setClearColor("#000000");
    renderer.setSize(width, height);

    this.mount.appendChild(renderer.domElement);

    // controls
    var controls = new OrbitControls(camera, renderer.domElement);
    //controls.addEventListener( 'change', render ); // call this only in static scenes (i.e., if there is no animation loop)
    //      controls.enableDamping = true; // an animation loop is required when either damping or auto-rotation are enabled
    //      controls.dampingFactor = 0.25;
    //    controls.panningMode = THREE.HorizontalPanning; // default is THREE.ScreenSpacePanning
    controls.minDistance = 100;
    controls.maxDistance = 2500;
    controls.maxPolarAngle = Math.PI / 2;

    //		scene.userData.element = element.querySelector( ".scene" );

    this.scene = scene;
    this.camera = camera;
    this.renderer = renderer;
    this.controls = controls;
  };

  renderScene() {
    //    console.log('renderScene', this);

    this.renderer.render(this.scene, this.camera);
  }

  updateScene() {
    //    console.log('updateScene', this);

    if (!!this.group) {
      this.scene.remove(this.group);
    }

    this.getColor();

    this.buildMaterial();

    this.buildModel();

    this.renderScene();
  }

  // react part
  componentDidMount() {
    this.getColor();

    this.buildMaterial();

    this.initScene();

    this.buildModel();

    this.renderScene();

    this.controls.addEventListener("change", this.renderScene.bind(this));
  }

  componentWillUnmount() {
    this.controls.removeEventListener("change", this.renderScene);
    //    this.mount.removeChild(this.renderer.domElement)  // react takes care ;)
  }
  /*
  shouldComponentUpdate(nextProps, nextState) {
//    console.log('should update?', nextProps);
    return nextProps.version !== this.props.version;
  }
*/

  componentDidUpdate() {
    //console.log('did update');
    // use new props
    if (!!this.renderer) this.updateScene();
  }

  render() {
    const version = this.props.version;
    const sx = {
      inlineTop: {
        display: "inline-block",
        verticalAlign: "top",
      },
    };
    return (
      <React.Fragment>
        <div
          ref={(mount) => {
            this.mount = mount;
          }}
          style={{ ...sx.inlineTop }}
        />
        {version ? version : null}
      </React.Fragment>
    );
  }
}

export default View3DThree;
