import i18n from "i18next";
// const i18n = require("i18next");
import LanguageDetector from "i18next-browser-languagedetector";
// const messages = require("./messages");
import messages from "./messages";

import en_diffuser from "./locales/en/diffuser.yaml";
import pl_diffuser from "./locales/pl/diffuser.yaml";

const options = {
  fallbackLng: "pl",
  //  load: 'languageOnly', // we only provide en, de -> no region specific locals like en-US, de-DE
  // Chrome returns regions
  load: "all", // we only provide en, de -> no region specific locals like en-US, de-DE
  debug: true,

  // whitelist: ["pl", "en", "de"],
  supportedLngs: ["pl", "en"],
  preload: ["pl", "en"],
  // cache: {
  //   enabled: true
  // },
  ns: ["translation", "diffuser"],
  defaultNS: "translation",

  interpolation: {
    escapeValue: false, // not needed for react!!
    formatSeparator: ",",
    format: (value, format, lng) => {
      if (format === "uppercase") return value.toUpperCase();
      return value;
    },
  },

  // basic set of translations
  resources: {
    en: {
      translation: {
        hello: "Hello World!",
        // tabs: {
        //   gallery: "photos",
        //   description: "description",
        //   neighborhood: "neighborhood",
        //   availability: "availability",
        // },
      },
    },
    pl: {
      translation: {
        hello: "Witaj Świecie!",
        // tabs: {
        //   gallery: "zdjęcia",
        //   description: "opis",
        //   neighborhood: "otoczenie",
        //   availability: "dostępność",
        // },
      },
    },
  },
};

i18n.use(LanguageDetector);

if (!i18n.isInitialized) {
  //  console.log('_i18n, before init');
  i18n.init(options);
  const hw = i18n.t("hello"); // hw = 'hello world'
  console.log("_i18n, hw", hw);
  for (let lng in messages) {
    console.log("_i18n, adding messages lng: ", lng);
    i18n.addResourceBundle(lng, "translation", messages[lng], true, true);
    //    console.log('_i18n, room.descriptionContentTitle', i18n.t('rooms.descriptionContentTitle') );
  }
  i18n.addResourceBundle("en", "diffuser", en_diffuser, true, true);
  i18n.addResourceBundle("pl", "diffuser", pl_diffuser, true, true);
}

export default i18n;
