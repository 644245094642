import React from 'react'
import { styles, space, colors } from './styles'

const Comment = ({
  big,
  asTips = false,
  //color = colors.white,
  color = colors.red,
  //backgroundColor = colors.blue,
  backgroundColor = colors.white,
  ...props
}) => {
  const sx = {                    
    base: {
      fontFamily: 'inherit',
      // fontSize: '0.8em',   
      lineHeight: 'normal',    
      fontWeight: styles.bold,
      textDecoration: 'none',
      display: 'block',
      margin: '1px',
      marginBottom: big ? space[2] : space[1],
      paddingTop: big ? space[2] : space[1],
      paddingBottom: big ? space[2] : space[1],
      paddingLeft: space[2],
      paddingRight: space[2],
      border: 0,
      color,
  //    backgroundColor,
      WebkitAppearance: 'none',
      MozAppearance: 'none',                      
    },
    asTips: {
      height: 0,        
      padding: '4px',
      margin: 0,
      backgroundColor: 'yellow',
      opacity: '0.5',
      fontSize: '1.0em',
      lineHeight: '1.1em',    
      display: 'inline-table',
      position: 'absolute',
    }
  };       
  let sxMod = asTips ? sx.asTips : {};
  if (window.showComments) return (             
     
    <div {...props} style={{ ...sx.base, ...sxMod, ...props.style}} >
    
      {props.children}
     </div>
  )
  return null;
}

export default Comment;