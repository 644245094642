export const white = '#fff'
export const black = '#111'
export const blue = '#07c'
export const red = '#e00'

export const colors = {
  white,
  black,
  blue,
  red,
}

export const space = [
  0,
  8,
  16,
  32,
  64
]

export const styles = {
  bold: 600,
  space,
  colors
}

//export default styles