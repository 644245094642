module.exports = {
  // PL
  commentsDabug: "KOMENTARZE I DANE POMOCNICZE",
  visible: "WIDOCZNE",
  hidden: "UKRYTE",
  POC: "Proof Of Concept - eksperymenty, testowanie idei",

  title: "Kalkulator dyfuzorów typu QRD",

  header: {
    title: "Willa u Władzi - pokoje nad morzem",
    subTitle: "pokoje do wynajęcia, pokoje z łazienkamo, nowe wyposażenie",
  },

  home: "Willa u Władzi",
  about: "O nas",
  contact: "Kontakt",

  clickToView: "zobacz zdjęcia {{title}}",
  clickViewMore: "naciśnij by zobaczyć więcej",
  clickChangeView: "naciśnij by zmienić widok",

  gallery: {
    descriptionContentTitle: "Zdjęcia",
    prev: "< poprzednie",
    next: "następne >",
  },

  calendar: {
    descriptionContentTitle: "Kalendarz rezerwacji",
    quantityAvailable: "Ilość tego typu obiektów: ",
    quantityOnlyOne: "Dysponujemy tylko jednym obiektem tego typu.",

    legend: {
      free: "wolne",
      partial: "częściowo zajęte",
      busy: "zajęte",
    },
    booking: {
      contentTitle: "Rezerwacja",
      contentText:
        "Przeczytaj opinie o naszych usługach, sprawdź ceny i zarezerwuj",
      buttonTitle: "przejdź do systemu rezerwacji",
      serviceText: "Rezerwacje obsługiwane są za pomocą serwisu: ",
    },
  },

  rooms: {
    descriptionContentTitle: "Opis i wyposażenie_PL",
    description: {
      parameterColumnTitle: "parametr",
      valueColumnTitle: "opis",

      entrance: "wejście",
      entranceSeparated: "odrębne wejście",

      livingRoom: "pokój dzienny",
      kitchen: "kuchnia",
      kitchenCommon: "wspólna kuchnia",
      kitchenSummer: "kuchnia letnia",
      kitchenette: "aneks kuchenny",

      bathroom: "łazienka",
      terrace: "taras",

      airConditioning: "klimatyzacja",
      tv: "TV",

      standardEquipment: "standardowe wyposażenie",
      fridge: "lodówka",
      sink: "zlew",
      stove: "kuchenka",
      microwave: "kuchenka mikrofalowa",

      beds: "łóżka",
      bedsQuantity: "ilość łóżek",
      bed: "łóżko",
      couch: "sofa",
      doubleBed: "łóżko małżeńskie",

      bath: "wanna",
      shower: "prysznic",

      seaView: "widok na morze",
      sunProtection: "ochrona od słońca",
    },
  },
};
