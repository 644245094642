/* eslint-disable react/prop-types */
import React, { Component } from "react";

import PropTypes from "prop-types";
import { withStyles, withTheme } from "@material-ui/core/styles";
import {
  // useTranslation,
  withTranslation,
  // WithTranslation,
} from "react-i18next";

import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Box from "@material-ui/core/Box";

import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import Switch from "@material-ui/core/Switch";
import Button from "@material-ui/core/Button";
import Select from "@material-ui/core/Select";
import { InputLabel } from "@material-ui/core";
import { MenuItem } from "@material-ui/core";
import { FormLabel } from "@material-ui/core";
import { FormGroup } from "@material-ui/core";
import { FormControl } from "@material-ui/core";

import { isPrime, getPrimesLimit } from "../data/prime";

import NumberFormat from "react-number-format";

import QRDBlocks from "./QRDBlocks";
import SVGBlockBar from "./SVGBlockBar";
import BlockBarParameters from "./BlockBarParameters";

import QRD2DGenerator from "./QRD2DGenerator";

import ColorPicker from "./ColorPicker";
import View3DThree from "./View3DThree";
import QRD2DDataSelector from "./QRD2DDataSelector";
import QRD2DViewer from "./QRD2DViewer";
import QRD2DMapChart from "./QRD2DMapChart";
import { saveAs } from "file-saver";

const TextToSVG = require("text-to-svg");
// const FileSaver = require('file-saver');
//import { FormControl } from '@material-ui/core/FormControl';
//import Input from 'material-ui/Input';
//import { FormHelperText } from 'material-ui/Form';

const styles = (theme) => ({
  tabs: {
    backgroundColor: theme.palette.grey["100"],
  },
});

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`tabpanel-${index}`}
      aria-labelledby={`tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography component="div">{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `tab-${index}`,
    "aria-controls": `tabpanel-${index}`,
  };
}

const DumpArray = (props) => {
  const { rowData } = props;
  return (
    <div>
      {rowData.map((el, k) => (
        <span key={k}> {el} </span>
      ))}
    </div>
  );
};

const DumpPoints = (props) => {
  const { rowPoints } = props;
  return (
    <div>
      {rowPoints.map((el, k) => (
        <span key={k}>
          {" "}
          ({el[0]}, {el[1]}){" "}
        </span>
      ))}
    </div>
  );
};

const NumberFormatCustom = (props) => {
  const { inputRef, onChange, ...other } = props;

  return (
    <NumberFormat
      {...other}
      ref={inputRef}
      onValueChange={(values) => {
        onChange({
          target: {
            value: values.value,
          },
        });
      }}
      thousandSeparator
      suffix=" mm"
    />
  );
};

NumberFormatCustom.propTypes = {
  inputRef: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
};

class QRDConverter extends Component {
  constructor(props) {
    super(props);

    //    this.primes = getPrimesLimit( 250 );
    this.state = {
      rowFileData: "10,   9,   8,   6,   4,   1,   18,   14,   10,   5",

      testData: "10,   9,   8,   6,   4,   1,   18,   14,   10,   5",
      blocks: [13, 23, 33, 4, 9, 16, 1],

      calculated: {
        dataset: [],
        meta: {},
        //        totalHeight: 0,
        stamp: "",
        valid: false,
      }, // results of calculations

      keepSquared: true, // keep rows=columns
      blocksInRow: 7, // columns
      blocksInColumns: 7, // rows

      // setting different value than above (row,col) => forced heights conversion table rebuilding
      periodLength: 2, // N - period  = max(rows,columns)

      keepCentered: true, // keep both offsets in a middle of 'picture'
      blocksMargin: 0, // column (horizontal) offset
      blocksTop: 0, // row (vertical) offset

      negative: true, // negative 'picture'

      minHeight: 0,
      maxHeight: 120,
      useRounding: true,

      // TO BE REMOVED - separate heights conversion table
      //      useHeight: !true,      // sound units or mm (from min-/maxHeight)

      rowSelected: 1, // 1-based index - 0 means other axis active
      columnSelected: 0, // 1-based index - 0 means other axis active

      useColumnsAsRows: !true, // orientation for processing
      //const stamp = ""+columns+"_"+rows+keepSquared+columnOffset+"_"+rowOffset+ negative+useHeight+minHeight+'_'+maxHeight+useRounding
      useHalfData: !true, // for centered data we can export only half of data
      // and use twice the same processing data/shapes

      blocksInProcessing: 7, // rows or colums
      blockWidth: 10,
      finWidth: 0, // also as block spacing
      baseHeight: 18,

      drawHoles: true,
      baseHoleNumber: 6,
      baseHoleDiam: 7,
      baseHoleMargin: 20,
      baseHoleBottom: 4,

      drawText: true,
      svgText: null,
      baseTextPrefix: "",
      leadingZeros: false,
      baseText: "",
      textSize: 16,
      baseTextMargin: 10,
      baseTextBottom: 3,
      baseSideWidth: 0,
      baseSideHeight: 0,
      baseSideBottom: 0,

      generateToolPaths: true,
      toolPathsOnly: true,
      toolDiameter: 3.175,
      strokeWidth: 3.0,

      showThree: false,
      threeUseHeights: true,
      threeColor: {
        r: 160,
        g: 100,
        b: 20,
        a: 1,
      },

      selectedTab: 0,
    };
    this.svgElement = null;

    this.api = {
      handleSquared: this.handleBoolChange("keepSquared").bind(this),
      handleRows: this.handleIntChange("blocksInColumns").bind(this),
      handleColumns: this.handleIntChange("blocksInRow").bind(this),
      handleCentered: this.handleBoolChange("keepCentered").bind(this),
      handleRowOffset: this.handleIntChange("blocksTop").bind(this),
      handleColumnOffset: this.handleIntChange("blocksMargin").bind(this),
      handleNegative: this.handleBoolChange("negative").bind(this),
      handleUseHeight: this.handleBoolChange("useHeight").bind(this),

      handleIntChange: this.handleIntChange.bind(this),
      handleFloatChange: this.handleFloatChange.bind(this),
      handleBoolChange: this.handleBoolChange.bind(this),

      handleUseRounding: this.handleBoolChange("useRounding").bind(this),

      handleUseColumnsAsRows: this.handleBoolChange("useColumnsAsRows").bind(
        this
      ),
      handleUseHalfData: this.handleBoolChange("useHalfData").bind(this),
      handleRowSelect: this.handleRowSelect.bind(this),
      handleColumnSelect: this.handleColumnSelect.bind(this),

      handleSVGDownload: this.handleSVGDownload, //.bind(this),

      setResult: this.setResult.bind(this),
      formatNumberLeading: this.formatNumberLeading.bind(this),
      formatPartNumber: this.formatPartNumber.bind(this),
      getHeights: this.getHeights.bind(this),
    };

    // prime numbers and squared primes precalculated
    let limit = 450;
    const primes = getPrimesLimit(limit);

    let squares = [];
    for (let i = 0; i < limit * 2; i++) {
      squares[i] = i * i;
    }

    this.primeNumbers = {
      limit: limit,
      primes: primes,
      squares: squares,
      api: {
        isPrime: isPrime,
        getPrimesLimit: getPrimesLimit,
      },
    };
    /*    
    this.rebuildHeights( {
      periodLength: nextState.periodLength,
      minHeight: nextState.minHeight,
      maxHeight: nextState.maxHeight,
      useRounding: nextState.useRounding
    } )
*/
    // this.classes = useStyles();
  }

  fontLoaded = (err, textToSVG) => {
    console.log("fontLoaded", textToSVG);

    this.setState({
      svgText: textToSVG,
    });
  };

  handleTabChange = (event, newValue) => {
    // setValue(newValue);
    this.setState({ selectedTab: newValue });
  };

  componentDidMount = () => {
    TextToSVG.load("/assets/1CamBam_Stick_9.ttf", this.fontLoaded);
  };

  shouldComponentUpdate = (nextProps, nextState) => {
    console.log("__QRD2DConv, SCU");
    //    validation, integrity checking

    // UX: parameter looping - results are periodical visually and by math
    // allowed (in UI-subcomponent) out of range data (invalid) gets proper value from opposite range end
    // sync: UI-subcomponent gets updated (valid) data with state changes (top-down propagation)
    const xCenter = Math.ceil(nextState.blocksInRow / 2);
    const yCenter = Math.ceil(nextState.blocksInColumns / 2);
    if (nextState.keepSquared) {
      if (nextState.blocksMargin >= nextState.blocksInRow)
        this.setState({ blocksMargin: 0 });
      if (nextState.blocksMargin < 0)
        this.setState({ blocksMargin: nextState.blocksInRow - 1 });
      if (nextState.blocksTop >= nextState.blocksInColumns)
        this.setState({ blocksTop: 0 });
      if (nextState.blocksTop < 0)
        this.setState({ blocksTop: nextState.blocksInColumns - 1 });
    } else {
      if (nextState.blocksMargin >= xCenter)
        this.setState({ blocksMargin: -xCenter + 1 });
      if (nextState.blocksMargin < -xCenter + 1)
        this.setState({ blocksMargin: xCenter - 1 });
      if (nextState.blocksTop >= yCenter)
        this.setState({ blocksTop: -yCenter + 1 });
      if (nextState.blocksTop < -yCenter + 1)
        this.setState({ blocksTop: yCenter - 1 });
    }

    // looping for data processing - NOT USED FOR NOW
    // rowSelected is 1-based index
    //    if ( nextState.blocksInColumns > nextState.rowSelected ) this.setState({rowSelected: 1 });

    // keep data offset in center (both cases squared or not)
    if (nextState.keepCentered) {
      const xd = nextState.keepSquared ? xCenter : 0;
      const yd = nextState.keepSquared ? yCenter : 0;
      if (nextState.blocksMargin !== xd || nextState.blocksTop !== yd) {
        //        console.log('__QRD2DConv, SCU keepCentered', nextState.blocksMargin, xd, nextState.blocksTop, yd );
        this.setState({
          blocksTop: yd,
          blocksMargin: xd,
        });
      }
    }

    // regenerate heights conversion table?
    let updateHeights = false;

    // periods (modulo) are calculated horizontal ...UPDATE: or vertical - greater of columns/rows decides
    // we should keep rows<columns to avoid repeated periods
    //if ( nextState.blocksInColumns > nextState.blocksInRow ) this.setState({blocksInColumns: nextState.blocksInRow });
    let newPeriod = Math.max(nextState.blocksInColumns, nextState.blocksInRow);
    if (newPeriod !== nextState.periodLength) {
      this.setState({ periodLength: newPeriod });
      updateHeights = true; // height range is divided by period
    }

    if (
      nextState.minHeight !== this.state.minHeight ||
      nextState.maxHeight !== this.state.maxHeight ||
      nextState.useRounding !== this.state.useRounding
    ) {
      updateHeights = true;
    }

    if (updateHeights) {
      this.rebuildHeights({
        // newPeriod has always actual/newer value
        periodLength: newPeriod, //nextState.periodLength,
        minHeight: nextState.minHeight,
        maxHeight: nextState.maxHeight,
        useRounding: nextState.useRounding,
      });
    }

    // sync height with width if keepSquared
    if (nextState.keepSquared) {
      if (nextState.blocksInRow !== nextState.blocksInColumns) {
        //        console.log('__QRD2DConv, SCU keepSquared' );
        this.setState({
          blocksInColumns: nextState.blocksInRow,
        });
      }
    }
    return true;
  };

  // called to change state, not using (possibly not valid) values from state
  // 'hacky' RORO pattern
  rebuildHeights = (options) => {
    // defaults
    let params = Object.assign({ minHeight: 0, useRounding: true }, options);
    const { periodLength, minHeight, maxHeight, useRounding } = params;
    let result = {};

    // conversion table  depth units to mm
    const unit = (maxHeight - minHeight) / (periodLength - 1);
    let heights = [];
    for (let i = 0; i < periodLength; i++) {
      if (useRounding) {
        heights.push(Math.round(i * unit + minHeight));
      } else {
        heights.push(+(Math.round(i * unit + minHeight + "e+2") + "e-2"));
      }
    }
    result.dataset = heights;

    // compatible way - instead of Object.values(), Array.from()
    // cons: values can be randomly ordered -
    // can't be used for user/human readibility
    // or identity with saved values (possible incompatibilities)
    // save params instead - stamp is derived data
    let stamp = Object.keys(params)
      .map((k) => params[k])
      .join("_");
    result.meta = {
      params: params,
      stamp: stamp,
    };

    this.setState({
      heights: result,
    });
    console.log("CONV rebuildHeights ", result, stamp, params);
  };
  // api fn
  getHeights = () => this.state.heights;

  // computed: shape points
  blockPoints = (rowData) => {
    const { baseSideWidth, baseSideHeight, baseSideBottom } = this.state;

    /*
    const commonHeights = this.state.heights
    let heights = (v) => v
    if(!!commonHeights) {
      heights = (v) => commonHeights.dataset[v] 
    }
*/
    let points = [];
    const wx = this.state.blockWidth + this.state.finWidth;

    // starting point for bars
    let dx = baseSideWidth;
    // level 0 for bars
    const dy = this.state.baseHeight;

    // side frame rect - left
    if (!!baseSideWidth && !!baseSideHeight) {
      if (!!baseSideBottom) {
        points.push([dx, 0]);
        points.push([dx, baseSideBottom]);
        points.push([0, baseSideBottom]);
        points.push([0, baseSideBottom + baseSideHeight]);
        points.push([dx, baseSideBottom + baseSideHeight]);
      } else {
        points.push([0, 0]);
        points.push([0, baseSideHeight]);
        points.push([dx, baseSideHeight]);
      }
    } else {
      dx = 0;
      points.push([0, 0]);
    }

    let cx = 0;
    let block = 0;
    console.log("CONV SVG", rowData);
    rowData.forEach((ry) => {
      cx = block++ * wx;
      //      points.push( [cx + dx,heights(ry) + dy] );
      points.push([cx + dx, ry + dy]);
      cx = block * wx;
      //      points.push( [cx + dx,heights(ry) + dy] );
      points.push([cx + dx, ry + dy]);
    });

    // side frame rect - right
    if (!!baseSideWidth && !!baseSideHeight) {
      if (!!baseSideBottom) {
        points.push([cx + dx, baseSideBottom + baseSideHeight]);
        points.push([cx + dx + dx, baseSideBottom + baseSideHeight]);
        points.push([cx + dx + dx, baseSideBottom]);
        points.push([cx + dx, baseSideBottom]);
        points.push([cx + dx, 0]);
      } else {
        points.push([cx + dx, baseSideHeight]);
        points.push([cx + dx + dx, baseSideHeight]);
      }
    }

    if (!baseSideWidth || !baseSideHeight || !baseSideBottom) {
      points.push([cx + dx + dx, 0]);
    }
    return points;
  };

  // computed: hole positions
  rodPoints = () => {
    let points = [];
    const {
      blocksInProcessing,
      blockWidth,
      finWidth,

      baseSideWidth,
      baseSideHeight,

      baseHoleNumber,
      baseHoleDiam,
      baseHoleMargin,
      baseHoleBottom,
    } = this.state;
    const sideFrame = !!baseSideWidth && !!baseSideHeight;

    const space = blockWidth + finWidth;
    const totalWidth = sideFrame
      ? blocksInProcessing * space + baseSideWidth + baseSideWidth
      : blocksInProcessing * space;

    const mx = baseHoleMargin;
    const d = baseHoleDiam;
    const holeRange = totalWidth - mx - mx - d;
    const dist = holeRange / (parseInt(baseHoleNumber, 10) - 1);
    const r = d / 2;

    let cy = baseHoleBottom + r;
    cy = +(Math.round(cy + "e+2") + "e-2");

    for (let h = 0; h < baseHoleNumber; h++) {
      let cx = h * dist + mx + r;
      cx = +(Math.round(cx + "e+2") + "e-2");
      points.push([cx, cy]);
    }
    return points;
  };

  handleChange = (name) => (event) => {
    this.setState({
      [name]: event.target.value,
    });
  };
  handleBoolChange = (name) => (event) => {
    this.setState({ [name]: event.target.checked });
  };

  // handleColorChange = (name) => (event) => {
  //   this.setState({ [name]: event.target.checked });
  // };

  handleIntChange = (name) => (event) => {
    const min = undefined === event.target.min ? "" : event.target.min;
    const newVal = parseInt(event.target.value, 10);
    if ("" !== min) {
      if (newVal >= min) {
        this.setState({
          [name]: newVal,
        });
      }
    } else {
      this.setState({
        [name]: newVal,
      });
    }
  };

  handleFloatChange = (name) => (event) => {
    const min = event.target.min;
    if ("" !== min) {
      const newVal = parseFloat(event.target.value);
      if (newVal >= min) {
        this.setState({
          [name]: newVal,
        });
      }
    }
  };

  handleSourceChange = (name) => (event) => {
    const newVal = event.target.value.split(",").map((v) => {
      return parseInt(v, 10) ? parseInt(v, 10) : 0;
    });
    if (!!newVal.length) {
      this.setState({
        [name]: event.target.value,
        blocks: newVal,
        blocksInRow: newVal.length,
      });
    }
  };

  handleDownload = (name) => (event) => {
    event.preventDefault();
    console.log("handleDownload", name, event.target);
    this.handleSVGDownload(
      name,
      "generated_" + this.formatPartNumber(this.state.rowSelected)
    );
  };

  formatNumberLeading = (num, maxLen = 0, fillChar = "0") => {
    let value = "" + num;
    let valueLen = value.length;
    let i = maxLen - valueLen;
    //    console.log(num, '>>'+fillChar+'<<', i, maxLen, valueLen)
    while (i-- > 0) {
      value = fillChar + value;
      //      console.log(i, '>>'+value+'<<')
    }
    //    console.log(num, '>>'+value+'<<')
    return value;
  };

  formatPartNumber = (num) => {
    if (!this.state.leadingZeros) return "" + num;
    let maxLen = ("" + this.state.blocksInColumns).length;
    return this.formatNumberLeading(num, maxLen, "0");
  };

  handleSVGDownload = (svg, fileName) => {
    // first create a clone of our svg node so we don't mess the original one
    var clone = svg.cloneNode(true);
    // parse the styles
    //parseStyles(clone);

    // create a doctype
    var svgDocType = document.implementation.createDocumentType(
      "svg",
      "-//W3C//DTD SVG 1.1//EN",
      "http://www.w3.org/Graphics/SVG/1.1/DTD/svg11.dtd"
    );
    // a fresh svg document
    var svgDoc = document.implementation.createDocument(
      "http://www.w3.org/2000/svg",
      "svg",
      svgDocType
    );
    // replace the documentElement with our clone
    svgDoc.replaceChild(clone, svgDoc.documentElement);
    // get the data
    var svgData = new XMLSerializer().serializeToString(svgDoc);

    // now you've got your svg data, the following will depend on how you want to download it
    // here I'll use FileSaver.js (https://github.com/yrezgui/FileSaver.js)

    var blob = new Blob([svgData.replace(/></g, ">\n\r<")]);
    // FileSaver.saveAs(blob, fileName + '.svg');
    saveAs(blob, fileName + ".svg");
  };

  setResult = (data) => {
    // prevent looping
    if (data.stamp !== this.state.calculated.stamp) {
      //      console.log('QRDConv data recalculated', rows, this.state.rowSelected);
      this.setState({
        calculated: data,
        version: data.stamp,
      });
      // rowSelected is 1-based index
      if (data.dataset.length < this.state.rowSelected) {
        //        console.log('QRDConv bad rowSelected  ');
        this.setState({ rowSelected: 1 });
      }
    }
  };

  handleRowSelect = (value) => {
    //    console.log('OUTER handleRowSelect', data)
    const heights = this.state.heights.dataset;
    const useHalfData = this.state.useHalfData;
    let dataset = this.state.calculated.dataset;
    if (useHalfData) {
      let halfIdx = Math.floor(dataset.length / 2);
      dataset = dataset.slice(halfIdx);
    }
    // 'human to computer index'
    // let row = this.state.calculated.dataset[ value - 1 ];
    let row = dataset[value - 1];
    if (undefined !== row) {
      row = row.map((v) => heights[v]);
      this.setState({
        testData: row,
        baseText: this.formatPartNumber(value),
        blocks: row,
        blocksInProcessing: row.length,
        rowSelected: value,
        columnSelected: 0,
        useColumnsAsRows: false,
      });
    }
  };

  handleColumnSelect = (value) => {
    //    console.log('OUTER handleRowSelect', data)
    const heights = this.state.heights.dataset;
    const useHalfData = this.state.useHalfData;
    let dataset = this.state.calculated.dataset;
    if (useHalfData) {
      let halfIdx = Math.floor(dataset[0].length / 2);
      // dataset = dataset.slice(halfIdx);
      dataset = dataset.map((item) => item.slice(halfIdx));
    }

    // 'human to computer index'
    // grab column into row
    // let row = this.state.calculated.dataset.slice().reverse().map( v => { return v[ value - 1 ] } );
    let row = dataset
      .slice()
      .reverse()
      .map((v) => {
        return v[value - 1];
      });
    if (undefined !== row) {
      //      console.log("set column ",row)
      row = row.map((v) => heights[v]);
      this.setState({
        testData: row,
        baseText: this.formatPartNumber(value),
        blocks: row,
        blocksInProcessing: row.length,
        rowSelected: 0,
        columnSelected: value,
        useColumnsAsRows: true,
      });
    }
  };

  render() {
    const txtReady = !!this.state.svgText;
    const {
      testData,

      periodLength,

      blocks,
      blocksInRow,
      blocksInColumns,
      keepSquared,
      blocksTop,
      blocksMargin,
      keepCentered,
      negative,
      calculated,
      rowSelected,
      columnSelected,

      useRounding,
      useHeight,
      useColumnsAsRows,
      useHalfData,

      blockWidth,
      finWidth,
      minHeight,
      maxHeight,
      baseHeight,

      drawHoles,
      baseHoleNumber,
      baseHoleDiam,
      baseHoleMargin,
      baseHoleBottom,

      drawText,
      svgText,
      baseTextPrefix,
      baseText,
      leadingZeros,
      textSize,
      baseTextMargin,
      baseTextBottom,
      baseSideWidth,
      baseSideHeight,
      baseSideBottom,

      generateToolPaths,
      toolPathsOnly,
      toolDiameter,
      strokeWidth,

      showThree,
      threeUseHeights,
      threeColor,

      selectedTab,
    } = this.state;
    const primes = this.primeNumbers.primes;
    const { classes, t } = this.props;
    const sx = {
      blockFormTitle: {
        marginBottom: 6,
        marginTop: 20,
      },
      switchLabel: {
        //        marginTop: 8,
        //        marginBottom: 8,
        //        position: 'relative',
        //        top: 10,
      },
      leftColumn: {
        width: 80,
      },
      wideColumn: {
        maxWidth: 140,
      },
    };

    const sideFrame = !!baseSideWidth && !!baseSideHeight;
    const diffuserWidth = blocksInRow * (blockWidth + finWidth);
    const totalWidth = sideFrame
      ? diffuserWidth +
        baseSideWidth +
        baseSideWidth +
        " (" +
        baseSideWidth +
        "+" +
        diffuserWidth +
        "+" +
        baseSideWidth +
        ")"
      : diffuserWidth;

    const holePoints = this.rodPoints();
    let textMargin = drawHoles
      ? holePoints[0][0] + baseHoleDiam + baseTextMargin
      : baseTextMargin;

    const columnError = !isPrime(blocksInRow);

    // render-time in-validation
    //        console.log('handleRowOffset(0) test', rows, columnOffset );
    //    if ( blocksMargin >= blocksInRow ) this.setState({blocksMargin: 0 });
    //    if ( blocksTop >= blocksInColumns ) this.setState({blocksTop: 0 });

    return (
      <div className={classes.root}>
        {/*                       
        <Typography variant="display1"  style={{...sx.blockFormTitle}} >
          Reqiurements
        </Typography>
        <div>
{/*        
          <TextField
            id="blocksInRow"                         
            label="Number of blocks in a row"      
            value={this.state.blocksInRow} 
            onChange={this.handleIntChange('blocksInRow')}
            type="number"
            inputProps={{
              min:"1",
            }}
          />
*/}
        <FormControl
          className={classes.formControl}
          error={columnError}
          style={{ ...sx.leftColumn }}
        >
          <InputLabel htmlFor="size-simple">
            {t("settings.periodLength", "Period length")}
          </InputLabel>
          <Select
            disabled
            value={periodLength}
            renderValue={(value) => `${value}N`}
            onChange={this.handleIntChange("periodLength")}
            inputProps={{
              name: "size",
              id: "size-simple",
            }}
          >
            {primes.map((n) => (
              <MenuItem key={n} value={n}>
                {n}
              </MenuItem>
            ))}
            ;
          </Select>
        </FormControl>

        <TextField
          value={totalWidth}
          label={t("settings.totalWidth", "Total diffuser width")}
          disabled
          inputProps={{
            "aria-label": "Total diffuser width",
          }}
          style={{ ...sx.leftColumn }}
        />

        <QRDBlocks
          blockWidth={blockWidth}
          finWidth={finWidth}
          minHeight={minHeight}
          maxHeight={maxHeight}
          useRounding={useRounding}
          api={this.api}
          //          primes={this.primeNumbers}
        />
        {/*
        <hr />      
*/}

        {!columnError && (
          <QRD2DGenerator
            periodLength={periodLength}
            params={{
              keepSquared: keepSquared,
              columns: blocksInRow,
              rows: blocksInColumns,
              keepCentered: keepCentered,
              columnOffset: blocksMargin,
              rowOffset: blocksTop,
              negative: negative,
              useHeight: useHeight,
              minHeight: minHeight,
              maxHeight: maxHeight,
              useRounding: useRounding,
            }}
            rowSelected={rowSelected}
            calculated={calculated}
            api={this.api}
            primes={this.primeNumbers}
          />
        )}

        <AppBar position="static">
          <Tabs
            value={selectedTab}
            onChange={this.handleTabChange}
            aria-label="tabs for sections"
            indicatorColor="secondary"
            textColor="secondary"
            centered
            className={classes.tabs}
          >
            <Tab label={t("tabs.data", "Data")} {...a11yProps(0)} />
            <Tab label={t("tabs.chart2D", "2D chart")} {...a11yProps(1)} />
            <Tab
              label={t("tabs.visualisation3D", "3D visualisation")}
              {...a11yProps(2)}
            />
            <Tab
              label={t("tabs.CNCProcessing", "CNC processing")}
              {...a11yProps(3)}
            />
          </Tabs>
        </AppBar>
        <TabPanel value={selectedTab} index={0}>
          <QRD2DViewer
            periodLength={periodLength}
            params={{
              keepSquared: keepSquared,
              columns: blocksInRow,
              rows: blocksInColumns,
              keepCentered: keepCentered,
              columnOffset: blocksMargin,
              rowOffset: blocksTop,
              negative: negative,
              useHeight: useHeight,
              minHeight: minHeight,
              maxHeight: maxHeight,
              useRounding: useRounding,
            }}
            rowSelected={rowSelected}
            calculated={calculated}
            api={this.api}
            primes={this.primeNumbers}
          />
        </TabPanel>

        <TabPanel value={selectedTab} index={1}>
          <QRD2DMapChart
            calculated={calculated}
            blockWidth={blockWidth}
            useHalfData={useHalfData}
            api={this.api}
          />
        </TabPanel>

        <TabPanel value={selectedTab} index={2}>
          <Typography variant="h5">
            {t("view3D.title", "3D visualisation")}
          </Typography>

          <FormControl
            className={classes.formControl}
            style={{ ...sx.leftColumn }}
          >
            <FormLabel component="legend" style={{ ...sx.switchLabel }}>
              {t("view3D.showThree3D", "show Three 3D")}
            </FormLabel>
            <FormGroup>
              <Switch
                checked={showThree}
                onChange={this.handleBoolChange("showThree")}
              />
            </FormGroup>
          </FormControl>

          {showThree && (
            <>
              <FormControl
                className={classes.formControl}
                style={{ ...sx.leftColumn }}
              >
                <FormLabel component="legend" style={{ ...sx.switchLabel }}>
                  {t("view3D.useHeights", "Użyj wysokości")}
                </FormLabel>
                <FormGroup>
                  <Switch
                    checked={threeUseHeights}
                    onChange={this.handleBoolChange("threeUseHeights")}
                  />
                </FormGroup>
              </FormControl>

              <FormControl>
                <FormLabel component="legend" style={{ ...sx.switchLabel }}>
                  {t("view3D.barColor", "bar color")}
                </FormLabel>
                <FormGroup>
                  <ColorPicker
                    value={threeColor}
                    onChange={this.handleChange("threeColor")}
                  />
                </FormGroup>
              </FormControl>

              <View3DThree
                blockPoints={this.state.calculated.dataset}
                version={this.state.version}
                meta="aabb"
                blockWidth={blockWidth}
                blockSpace={finWidth}
                blockColor={threeColor}
                viewWidth={1200}
                viewHeight={908}
                usingHeights={threeUseHeights}
                heights={this.state.heights.dataset}
              />
            </>
          )}
        </TabPanel>
        <TabPanel value={selectedTab} index={3}>
          <QRD2DDataSelector
            periodLength={periodLength}
            rows={blocksInColumns}
            columns={blocksInRow}
            keepSquared={keepSquared}
            rowOffset={blocksTop}
            columnOffset={blocksMargin}
            keepCentered={keepCentered}
            useHeight={useHeight}
            useRounding={useRounding}
            useColumnsAsRows={useColumnsAsRows}
            useHalfData={useHalfData}
            minHeight={minHeight}
            maxHeight={maxHeight}
            negative={negative}
            calculated={calculated}
            rowSelected={rowSelected}
            columnSelected={columnSelected}
            blockWidth={blockWidth}
            api={this.api}
            primes={this.primeNumbers}
          />

          <hr />
          <Typography variant="h5">
            {t("cnc.visualisation", "visualisation")}
          </Typography>

          {txtReady && (
            <React.Fragment>
              <SVGBlockBar
                blockPoints={this.blockPoints(blocks)}
                drawHoles={drawHoles}
                holeDiam={baseHoleDiam}
                holePoints={this.rodPoints()}
                drawText={drawText}
                textPaths={svgText.getD(baseTextPrefix + "" + baseText, {
                  fontSize: textSize,
                  letterSpacing: 0.2,
                })}
                baseTextMargin={textMargin}
                baseTextBottom={baseTextBottom}
                svgRef={(el) => {
                  this.svgElement = el;
                }}
                generateToolPaths={generateToolPaths}
                toolPathsOnly={toolPathsOnly}
                toolDiameter={toolDiameter}
                strokeWidth={strokeWidth / 2}
                baseSideWidth={baseSideWidth}
                baseSideHeight={baseSideHeight}
                baseSideBottom={baseSideBottom}
                maxHeight={maxHeight}
                baseHeight={baseHeight}
              />

              {!!this.svgElement ? (
                <React.Fragment>
                  {/*
              <a href="" onClick={ this.handleDownload(this.svgElement).bind( this) } >DOWLOAD SVG
              </a>
*/}
                  <Button
                    disableFocusRipple
                    fullWidth
                    color="default"
                    size="medium"
                    variant="outlined"
                    onClick={this.handleDownload(this.svgElement).bind(this)}
                  >
                    {t("cnc.dowloadSVG", "Dowload SVG")}
                  </Button>

                  {window.showComments && (
                    <React.Fragment>
                      <code>{this.svgElement.outerHTML}</code>
                    </React.Fragment>
                  )}
                </React.Fragment>
              ) : (
                <span style={{ color: "red" }}>
                  {t("cnc.selectDataToDownload", "select data source")}
                </span>
              )}
            </React.Fragment>
          )}

          <BlockBarParameters
            baseHeight={baseHeight}
            baseSideWidth={baseSideWidth}
            baseSideHeight={baseSideHeight}
            baseSideBottom={baseSideBottom}
            api={this.api}
          />

          <Typography variant="h5" style={{ ...sx.blockFormTitle }}>
            {t("cnc.holesForRods.title", "Draw holes for rods")}
          </Typography>
          <FormControl className={classes.formControl}>
            <FormLabel component="legend" style={{ ...sx.switchLabel }}>
              {t("cnc.holesForRods.useHoles", "Use holes")}
            </FormLabel>
            <FormGroup>
              <Switch
                checked={drawHoles}
                onChange={this.handleBoolChange("drawHoles")}
                value="drawHoles"
              />
            </FormGroup>
          </FormControl>

          <TextField
            style={{ ...sx.leftColumn }}
            id="baseHoleNumber"
            label={t("cnc.holesForRods.amount", "Amount of base rods")}
            value={baseHoleNumber}
            onChange={this.handleIntChange("baseHoleNumber")}
            type="number"
            inputProps={{
              min: "2",
            }}
            disabled={!drawHoles}
          />

          <TextField
            style={{ ...sx.leftColumn }}
            id="baseHoleDiam"
            label={t("cnc.holesForRods.diameter", "Base hole diameter")}
            value={baseHoleDiam}
            onChange={this.handleFloatChange("baseHoleDiam")}
            type="number"
            inputProps={{
              min: "0.1",
            }}
            disabled={!drawHoles}
          />
          <TextField
            style={{ ...sx.leftColumn }}
            id="baseHoleMargin"
            label={t("cnc.holesForRods.sideMargin", "Side hole margin")}
            value={baseHoleMargin}
            onChange={this.handleIntChange("baseHoleMargin")}
            type="number"
            inputProps={{
              min: "0",
            }}
            disabled={!drawHoles}
          />
          <TextField
            style={{ ...sx.leftColumn }}
            id="baseHoleBottom"
            label={t("cnc.holesForRods.bottomMargin", "Bottom hole margin")}
            value={baseHoleBottom}
            onChange={this.handleIntChange("baseHoleBottom")}
            type="number"
            inputProps={{
              min: "0",
            }}
            disabled={!drawHoles}
          />

          <Typography variant="h5" style={{ ...sx.blockFormTitle }}>
            {t("cnc.partsNumeration.title", "Draw part numbers")}
          </Typography>

          <FormControl className={classes.formControl}>
            <FormLabel component="legend" style={{ ...sx.switchLabel }}>
              {t("cnc.partsNumeration.useNumeration", "Use numeration")}
            </FormLabel>
            <FormGroup>
              <Switch
                id="draw-numbers"
                checked={drawText}
                onChange={this.handleBoolChange("drawText")}
                value="drawText"
                classes={classes.formControl}
              />
            </FormGroup>
          </FormControl>

          <TextField
            id="baseTextPrefix"
            label={t("cnc.partsNumeration.prefix", "Part number prefix")}
            value={baseTextPrefix}
            onChange={this.handleChange("baseTextPrefix")}
            disabled={!drawText}
          />

          <FormControl className={classes.formControl}>
            <FormLabel component="legend" style={{ ...sx.switchLabel }}>
              {t("cnc.partsNumeration.zeros", "Leading zeros")}
            </FormLabel>
            <FormGroup>
              <Switch
                checked={leadingZeros}
                onChange={this.handleBoolChange("leadingZeros")}
              />
            </FormGroup>
          </FormControl>

          <TextField
            id="baseText"
            label={t("cnc.partsNumeration.number", "Part number")}
            value={baseText}
            onChange={this.handleChange("baseText")}
            disabled={!drawText}
          />

          <TextField
            style={{ ...sx.leftColumn }}
            id="textSize"
            label={t("cnc.partsNumeration.size", "Text size")}
            value={textSize}
            onChange={this.handleIntChange("textSize")}
            type="number"
            inputProps={{
              min: "1",
            }}
          />

          <TextField
            style={{ ...sx.leftColumn }}
            id="baseTextMargin"
            label={t("cnc.partsNumeration.sideMargin", "Side text margin")}
            value={baseTextMargin}
            onChange={this.handleIntChange("baseTextMargin")}
            type="number"
            inputProps={{
              min: "0",
            }}
            disabled={!drawText}
          />

          <TextField
            style={{ ...sx.leftColumn }}
            id="baseTextBottom"
            label={t("cnc.partsNumeration.bottomMargin", "Bottom text margin")}
            value={baseTextBottom}
            onChange={this.handleIntChange("baseTextBottom")}
            type="number"
            inputProps={{
              min: "0",
            }}
            disabled={!drawText}
          />
          {/*          
        </div>
*/}
          <hr />

          <Typography variant="h5" style={{ ...sx.blockFormTitle }}>
            {t("cnc.processing.title", "Processing parameters")}
          </Typography>

          <FormControl className={classes.formControl}>
            <FormLabel component="legend" style={{ ...sx.switchLabel }}>
              {t("cnc.processing.generatePaths", "Generate paths")}
            </FormLabel>
            <FormGroup>
              <Switch
                id="generate-tool-paths"
                checked={generateToolPaths}
                onChange={this.handleBoolChange("generateToolPaths")}
                value="generateToolPaths"
                classes={classes.formControl}
              />
            </FormGroup>
          </FormControl>

          <TextField
            style={{ ...sx.leftColumn }}
            id="toolDiameter"
            label={t("cnc.processing.diameter", "Tool diameter")}
            value={toolDiameter}
            onChange={this.handleFloatChange("toolDiameter")}
            type="number"
            inputProps={{
              min: "0.1",
            }}
          />
          <TextField
            style={{ ...sx.leftColumn }}
            id="strokeWidth"
            label={t("cnc.processing.pathWidth", "Tool path width")}
            value={strokeWidth}
            onChange={this.handleFloatChange("strokeWidth")}
            type="number"
            inputProps={{
              min: "0.1",
            }}
          />

          <FormControl className={classes.formControl}>
            <FormLabel component="legend" style={{ ...sx.switchLabel }}>
              {t("cnc.processing.pathsOnly", "Tool paths only")}
            </FormLabel>
            <FormGroup>
              <Switch
                id="tool-paths-only"
                checked={toolPathsOnly}
                onChange={this.handleBoolChange("toolPathsOnly")}
                value="toolPathsOnly"
                classes={classes.formControl}
              />
            </FormGroup>
          </FormControl>

          {window.showComments && (
            <>
              <hr />

              <Typography variant="h6">
                {t("cnc.testing.title", "Testing source data")}
              </Typography>
              <div style={{ paddingRight: "18px" }}>
                <TextField
                  id="testData"
                  label={t(
                    "cnc.testing.enterDataHint",
                    "Enter block heights [comma separated]"
                  )}
                  value={testData}
                  onChange={this.handleSourceChange("testData")}
                  fullWidth
                />
              </div>
            </>
          )}

          {window.showComments && (
            <React.Fragment>
              <Typography variant="h6">
                {t("cnc.testing.blockSet", "Testing block set")}
              </Typography>
              <DumpArray rowData={blocks} />

              <Typography variant="h6">
                {t("cnc.testing.blockShape", "Block's shape")}
              </Typography>
              <DumpPoints rowPoints={this.blockPoints(blocks)} />

              <Typography variant="h6">
                {t("cnc.testing.rodPositions", "Rods (holes) positions")}
              </Typography>
              <DumpPoints rowPoints={this.rodPoints()} />
            </React.Fragment>
          )}
        </TabPanel>

        <hr />
      </div>
    );
  }
}

QRDConverter.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withTranslation("diffuser")(
  withStyles(styles)(withTheme(QRDConverter))
);
//export default withStyles()(QRDConverter);
