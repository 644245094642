import React, { Component } from 'react'
import PropTypes from 'prop-types';
                                
const createCellGrid = ( columns, rows, cellWidth, cellHeight, elementCreator, getter ) => {
  let result = []
  for( let row=0; row<rows; row++ ) {
    let blocks = []
    let rowY = row * cellHeight 
    for( let column=0; column<columns; column++ ) {                 
      blocks.push(
        elementCreator ( column, row, cellWidth, cellHeight, column*cellWidth, rowY, getter )           
      )
    }  
    result.push( <g key={"row_"+row}>{blocks}</g> )
  }                          
  return result
} 

const rectCreator = ( column, row, cellWidth, cellHeight, columnX, rowY ) => 
  <rect className="svg-cell" key={"cell_"+column+"_"+row}
    width={cellWidth}
    height={cellHeight}
    x={columnX}
    y={rowY}
  />



class CenteredText extends Component {
  state = {
    x: 0,
    y: 0,
  };

  componentDidMount() {
    this.startOffset = this.node.getBBox(); 
    this.updateBBox( this.startOffset );
  }                       

  componentDidUpdate(prevProps) {
    if (prevProps !== this.props) {        
      this.updateBBox( this.node.getBBox() ); 
    }
  }

  updateBBox = (bbox) => {
    this.setState({
      bbox,
      x: -bbox.width/2 - this.startOffset.x,
      y: -(bbox.height/2),
    });
  };

  render() {
    let dx = this.props.x
    let dy = this.props.y
    const { value, transform } = this.props 
    let { x, y } = this.state
    
    return (
      <text
        key="text"
        ref={node => {
          this.node = node;
        }}
//        alignmentBaseline="center"
//        dominantBaseline="middle"
        transform={!!transform ? transform :` translate(${x+dx},${y+dy})`}
      >
        <tspan x={x.toFixed(2)} y={y.toFixed(2)} dy="1.0em">
          {value}
        </tspan>
      </text>
    )
  
  }     
}

// wrapper for 
const centeredTextCreator = ( column, row, cellWidth, cellHeight, columnX, rowY, getter ) => 
  <CenteredText key={"cell_"+column+"_"+row}
//    x={column * cellWidth}
//    y={-dy} 
    transform={`translate(${columnX},${ -rowY })`}
    value={ getter( column, row ) }
  />
              
  
// draw cell grid using rectangles
const CellRectGrid = (props) => {
  const {      
    rows,
    columns,
    cellWidth,
    cellHeight,
  } = props;
  const sx = {             
    fill: "none",
    stroke: "lightgrey",
    strokeWidth:"0.1mm",
  }                       
  return (   
    <g id="table cells" style={sx} >       
      { createCellGrid( columns, rows, cellWidth, cellHeight, rectCreator ) }
    </g>       
  );
}


const TableValues = (props) => {
  const {
    cellWidth,
    cellHeight,
    dataset,      
    getValue,
    getLabel,
    showLabels,
  } = props;
  let {
    rows,
    columns,
  } = props;
  if( undefined !== dataset ) {
    rows = dataset.length
    columns = dataset[0].length
  }
  
  const sx = {             
    values: {
      fontSize:5,
    },
    labels: {
      fontSize:2,
    }
  }           

  // getter - can be used to procedural fill
  // getValue={(x,y,v)=>y+10*x}             
  // ... or to calculate derived value from original (from dataset) 
  // f.e. getValue={(x,y,v)=>v+10*x}
  // undefined same as getValue={(x,y,v)=>v}                                                   

  // getter options simplified for long loop usage
  const prepareValueGetter = (getter) => {
    if( undefined !== dataset ) {
      if( !getter ) return ( (x,y)=>dataset[y][x] )   // raw data
      return( (x,y)=>getter( x, y, dataset[y][x] ) )    // getter as decorator 
    } else {
      if( !getter ) {
        console.log('SVG TABLE no dataset, no getter function')
        return ( ()=>null )
      }
      return( (x,y)=>getter( x, y ) )    // getter as data provider  
    }
  }
  
  const commonTransform = `scale(1, -1) translate(${cellWidth/2},${-cellHeight/2})`
  const valuesTransform = commonTransform + (showLabels?  ' translate(0,-1.2)' :'') 
  const labelsTransform = commonTransform + (showLabels?  ' translate(0,2.4)' :'') 

  return ( 
    <g id="cell values" key="cells">
      <g id="values" key="values"            
        style={sx.values}
        transform={valuesTransform}
        >
        { createCellGrid( columns, rows, cellWidth, cellHeight, centeredTextCreator, prepareValueGetter( getValue ) ) }
      </g>
      {showLabels && 
      <g id="labels" key="labels"            
        style={sx.labels}
        transform={labelsTransform}
        >
        { createCellGrid( columns, rows, cellWidth, cellHeight, centeredTextCreator, prepareValueGetter( getLabel ) ) }
      </g>     
      }
    </g>     
  );
}           



const SVGTable = (props) => {
  const {
    cellWidth,
    cellHeight,
    dataset,                  
    getValue,
    getLabel,      
    showLabels,
  } = props;
  let {
    columns,
    rows,
  } = props;

  const sx = {    
    drawing: {
      width: "100%", 
      height: "50%",
      maxHeight: 600,
    },                    
    frame: {
      fill: "none",
      stroke: "grey",
      strokeWidth:"0.2mm",
    },

    toolPath: {
      stroke: "green",     
//      strokeWidth: strokeWidth/2 + "mm",
      strokeLinecap: "round",             
      strokeLinejoin: "round", 
      fill: "none", 
    },
  }

                                                         
  if( undefined!==dataset ) {            
    if(!dataset.length) return ( <span>PROCESSING</span> )
    rows = dataset.length
    columns = dataset[0].length
  } else {
    if( (undefined===rows) || (undefined===rows) ) {
      console.log('SVG TABLE bad parameters')
      return ( null )
    }
  }  
   
  const contentWidth = columns * cellWidth
  const contentHeight = rows * cellHeight
  
  const svgMargin = 2
  const drawWidth =  contentWidth+svgMargin*2
  const drawHeight = contentHeight +svgMargin*2     
  
//   debugger
  
  return (  
    <svg ref={props.svgRef} style={sx.drawing} 
      viewBox={`-${svgMargin} -${svgMargin} ${drawWidth} ${drawHeight}`} 
      width={`${drawWidth}mm`} 
      height={`${drawHeight}mm`} 
      >
      <g id="AllBlocks" transform={`
        scale(1, -1) 
        translate(0, -${contentHeight})
      `}>        
        <g id="drawing" key="drawing">

          <rect id={"table frame"} className="svg-frame" 
            style={sx.frame}
            x={0}
            y={0}
            width={contentWidth}
            height={contentHeight}
          />
          
          <CellRectGrid
            rows={rows}
            columns={columns}        
            cellWidth={cellWidth}
            cellHeight={cellHeight}
          />       
        
          <TableValues
            rows={rows}
            columns={columns}
            cellWidth={cellWidth}
            cellHeight={cellHeight}

            dataset={dataset}
            getValue={getValue}
            getLabel={getLabel}     

            showLabels={showLabels}            
          />
        </g>
      </g>   
    </svg>
  )
}
                        
SVGTable.propTypes = {
  rows: PropTypes.number,
  columns: PropTypes.number,
  cellWidth: PropTypes.number.isRequired,
  cellHeight: PropTypes.number.isRequired,

  dataset: PropTypes.array,       
  getValue: PropTypes.func,
  getLabel: PropTypes.func,
  showLabels: PropTypes.bool,
}

export default SVGTable;