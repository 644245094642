import React from "react";
import PropTypes from "prop-types";
import { withTheme, makeStyles } from "@material-ui/core/styles";
import {
  useTranslation,
  // withTranslation,
  // WithTranslation,
} from "react-i18next";

import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
//import Select from 'material-ui/Select';
//import Switch from 'material-ui/Switch';
//import { InputLabel } from 'material-ui/Input';
//import { MenuItem } from 'material-ui/Menu';
//import { FormLabel, FormGroup, FormControl, FormHelperText } from 'material-ui/Form';

import "./QRD2DDataSelector.css";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    // maxWidth: "36ch",
    backgroundColor: theme.palette.background.paper,
  },
  leftColumn: {
    width: 100,
  },
  blockFormTitle: {
    marginBottom: 6,
    marginTop: 20,
  },
}));

// class BlockBarParameters extends React.Component {
const BlockBarParameters = (props) => {
  const {
    baseHeight,
    baseSideWidth,
    baseSideHeight,
    baseSideBottom,

    //      classes,  // theme
    api,
  } = props;

  const classes = useStyles();

  const { t } = useTranslation("diffuser");

  return (
    <div>
      <Typography variant="h5" className={classes.blockFormTitle}>
        {t("cnc.blockBarModifiers.title", "Block bar shape modifiers")}
      </Typography>

      <TextField
        className={classes.leftColumn}
        id="baseHeight"
        label={t("cnc.blockBarModifiers.baseHeight", "Base height")}
        value={baseHeight}
        onChange={api.handleFloatChange("baseHeight")}
        type="number"
        inputProps={{
          min: "0",
        }}
      />

      <TextField
        className={classes.leftColumn}
        id="baseSideWidth"
        label={t("cnc.blockBarModifiers.frameWidth", "Frame width")}
        value={baseSideWidth}
        onChange={api.handleIntChange("baseSideWidth")}
        type="number"
        inputProps={{
          min: "0",
        }}
      />

      <TextField
        className={classes.leftColumn}
        id="baseSideHeight"
        label={t("cnc.blockBarModifiers.frameHeight", "Frame height")}
        value={baseSideHeight}
        onChange={api.handleIntChange("baseSideHeight")}
        type="number"
        inputProps={{
          min: "0",
        }}
      />

      <TextField
        className={classes.leftColumn}
        id="baseSideBottom"
        label={t("cnc.blockBarModifiers.bottomMargin", "Frame bottom margin")}
        value={baseSideBottom}
        onChange={api.handleIntChange("baseSideBottom")}
        type="number"
        inputProps={{
          min: "0",
        }}
      />
    </div>
  );
};

BlockBarParameters.propTypes = {
  //  periodLength: PropTypes.number.isRequired,
  //  periodLength: PropTypes.number.isRequired,
  baseHeight: PropTypes.number.isRequired,
  baseSideWidth: PropTypes.number.isRequired,
  baseSideHeight: PropTypes.number.isRequired,
  baseSideBottom: PropTypes.number.isRequired,

  //  calculated: PropTypes.object.isRequired,
  //  primes: PropTypes.object.isRequired,

  //  classes: PropTypes.object.isRequired,
  api: PropTypes.object.isRequired,
  //  setResult: PropTypes.func.isRequired,
};

// export default withStyles()(withTheme(BlockBarParameters));
export default withTheme(BlockBarParameters);
