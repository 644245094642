module.exports = {
  // EN
  commentsDabug: "COMMENTS AND DEBUG DATA",
  visible: "VISIBLE",
  hidden: "HIDDEN",
  POC: "Proof Of Concept - evaluating miscelanous ideas",

  title: "QRD-type acoustic diffuser calculator",

  home: "Home",
  about: "About us",

  contact: "Contact",

  clickToView: "see photos {{title}}",
  clickViewMore: "press to view more",
  clickChangeView: "click to change view",

  gallery: {
    descriptionContentTitle: "Photos",
    prev: "< prev",
    next: "next >",
  },

  calendar: {
    descriptionContentTitle: "Booking calendar",
    quantityAvailable: "Number of this type of accommodation: ",
    quantityOnlyOne: "We have only one object of this type.",

    legend: {
      free: "free",
      partial: "partially busy",
      busy: "busy",
    },

    booking: {
      contentTitle: "Reservation",
      contentText: "Read reviews about our services, check prices and book",
      buttonTitle: "Go to booking system",
      serviceText: "Our reservations are served via the website: ",
    },
  },

  rooms: {
    descriptionContentTitle: "Description and equipment",
    description: {
      parameterColumnTitle: "parameter",
      valueColumnTitle: "description",

      entrance: "entrance",
      entranceSeparated: "separated entrance",

      livingRoom: "living room",
      kitchen: "kitchen",
      kitchenCommon: "common kitchen",
      kitchenSummer: "summer kitchen",
      kitchenette: "kitchenette",

      bathroom: "bathroom",
      terrace: "terrace",

      airConditioning: "air conditioning",
      tv: "TV",

      standardEquipment: "standard equipment",
      fridge: "fridge",
      sink: "sink",
      stove: "stove",
      microwave: "microwave",

      beds: "beds",
      bedsQuantity: "beds quantity",
      bed: "bed",
      couch: "couch",
      doubleBed: "double bed",

      bath: "bath",
      shower: "shower",

      seaView: "sea view",
      sunProtection: "sun protection",
    },
  },
};
