const isPrime = (n) => {
  if (n === 2) { return true; }
  if (n % 2 === 0) { return false; }
  const root = Math.sqrt(n);
  for(let i = 3, s = root; i <= s; i += 2) {
    if (n % i === 0) { return false; }
  }
  return n !== 1;
}
           
// prime from prime
const nextPrime = (value) => {
  if (value > 2) {
    let i, q;
    do {
      i = 3;
      value += 2;
      q = Math.floor(Math.sqrt(value));
      while (i <= q && value % i) {
        i += 2;
      }
    } while (i <= q);
    return value;
  }
  return value === 2 ? 3 : 2;
}                      
                  
// n primes
const getPrimes = (limit) => {
  let value, result = [];
  for (let i = 0; i < limit; i++) {
    value = nextPrime(value);
    result.push(value);
  }
  return result;  
}

// primes lower than limit
const getPrimesLimit = (limit) => {
  let value = 2, result = [];
  while (value < limit) {
    result.push(value);
    value = nextPrime(value);
  }
  return result;  
}



module.exports = {
  isPrime: isPrime,
  nextPrime: nextPrime,
  getPrimes: getPrimes,
  getPrimesLimit: getPrimesLimit,
}