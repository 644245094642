import React from 'react';
import ReactDOM from 'react-dom';
import i18n from './data/i18n';
import { I18nextProvider } from 'react-i18next';
import App from './components/App';
import './index.css';
                                                 
window.showComments = !0;

ReactDOM.render(
  <I18nextProvider i18n={ i18n }>
    <div>
      <App />
    </div>                   
  </I18nextProvider>,
  document.getElementById('root')
);
