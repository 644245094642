/* eslint-disable react/prop-types */
import React, { Fragment } from "react";
import PropTypes from "prop-types";
import { withStyles, withTheme } from "@material-ui/core/styles";
import {
  // useTranslation,
  withTranslation,
  // WithTranslation,
} from "react-i18next";

import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
//import Select from 'material-ui/Select';
import Switch from "@material-ui/core/Switch";
//import { InputLabel } from 'material-ui/Input';
//import { MenuItem } from 'material-ui/Menu';
//import { FormLabel, FormGroup, FormControl, FormHelperText } from 'material-ui/Form';
import { FormLabel } from "@material-ui/core";
import { FormGroup } from "@material-ui/core";
import { FormControl } from "@material-ui/core";
import { FormHelperText } from "@material-ui/core";

import QRD2DDataTable from "./QRD2DDataTable";

import { QRDStats } from "./QRDStats";
import BOM from "./BOM";

import "./QRD2DDataSelector.css";

const styles = (theme) => ({
  root: {
    width: "100%",
    // maxWidth: "36ch",
    backgroundColor: theme.palette.background.paper,
  },
  leftColumn: {
    width: 100,
  },
  blockFormTitle: {
    marginBottom: 6,
    marginTop: 20,
  },
});

class QRD2DDataSelector extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      showTable: false,
      showHeights: false,
      rowSelected: 0,
      columnSelected: 0,
    };
  }

  // local handlers
  handleBoolChange = (name) => (event) => {
    this.setState({ [name]: event.target.checked });
  };

  setRowSelected = (value) => {
    // local save value
    if (value > -1 && value < this.props.rows + 1) {
      this.setState({
        rowSelected: value,
        columnSelected: 0,
        useColumnsAsRows: false,
      });
    }
    // parent's handler
    this.props.api.handleRowSelect(value);
  };

  setColumnSelected = (value) => {
    // local save value
    if (value > -1 && value < this.props.columns + 1) {
      this.setState({
        rowSelected: 0,
        columnSelected: value,
        useColumnsAsRows: true,
      });
    }
    // parent's handler
    this.props.api.handleColumnSelect(value);
  };

  handleRowSelected = () => (event) => {
    this.setRowSelected(+event.target.value);
  };

  handleColumnSelected = () => (event) => {
    this.setColumnSelected(+event.target.value);
  };

  componentDidUpdate() {
    //    console.log("SEL CDU");
    if (this.state.rowSelected !== this.props.rowSelected) {
      this.setState({ rowSelected: this.props.rowSelected });
    }
    if (this.state.columnSelected !== this.props.columnSelected) {
      this.setState({ columnSelected: this.props.columnSelected });
    }
  }

  render() {
    const {
      //      periodLength,
      //      rows,
      // columns,
      minHeight,
      maxHeight,
      useRounding,
      //      useHeight,
      useColumnsAsRows,
      useHalfData,
      blockWidth,

      classes, // theme
      primes,
      api,
      t,
    } = this.props;
    const squares = this.props.primes.squares;
    const handleUseHalfData = this.props.api.handleUseHalfData;
    const handleUseColumnsAsRows = this.props.api.handleUseColumnsAsRows;

    const { showTable, showHeights, rowSelected, columnSelected } = this.state;

    const calculated = this.props.calculated;
    //    const { dataset, meta } = calculated
    let rows = this.props.rows;
    let columns = this.props.columns;
    let meta = { ...this.props.calculated.meta };
    let dataset = this.props.calculated.dataset;
    if (useHalfData) {
      let halfIdx = Math.floor(dataset.length / 2);
      if (useColumnsAsRows) {
        let halfIdx = Math.floor(dataset[0].length / 2);
        console.log(" MapChart COL halfIdx", halfIdx);
        columns = columns - halfIdx;
        // dataset = dataset.map(item => item.slice(0, halfIdx - 1));
        dataset = dataset.map((item) => item.slice(halfIdx));
      } else {
        console.log(" MapChart halfIdx", halfIdx);
        rows = rows - halfIdx;
        dataset = dataset.slice(halfIdx);
        meta.rowData = meta.rowData.slice(halfIdx);
      }
    }

    const currentStamp = this.props.calculated.stamp;
    //    const bomElements = !!meta.stats ? meta.stats.bomElements : []

    // periodLength can be out of sync
    //    const maxLevels = rows>columns?rows:columns

    const sx = {
      blockFormTitle: {
        marginBottom: 6,
        marginTop: 20,
      },
      switchLabel: {
        //        marginTop: 10,
        //        marginBottom: 8,
        //        position: 'relative',
        //        top: 13,
      },
      infoColumn: {
        display: "inline-block",
        verticalAlign: "middle",
        maxWidth: 250,
      },
      leftColumn: {
        width: 100,
      },
      wideColumn: {
        maxWidth: 200,
      },
      cell: {
        display: "inline",
        padding: 12,
      },
      cellScrolled: {
        fontSize: 11,
        overflowX: "auto",
        overflowY: "auto",
        display: "inline-block",
        verticalAlign: "middle",
        maxWidth: 800,
        maxHeight: 600,
        padding: 4,
      },
    };

    /*
    // 'alphas' between base and limit as 'procents'    
    const colorStep = (n, limit, base=20) => {
      let unit = useHeight ? (limit - base)/(maxHeight-minHeight+1) : (limit - base)/(maxLevels-1);
      let value = (n-minHeight)*unit+base;
      return (value/100).toFixed(2); 
    }
    
    const hideScrolling = 25*rows > 590 ? { overflowY:'auto' } : { overflowY:'hidden' }
*/
    //    console.log("SEL render")

    return (
      <div>
        <Typography variant="h5" style={{ ...sx.blockFormTitle }}>
          {t("cnc.dataSelector.title", "Processing data selector")}
        </Typography>

        <FormControl
          className={classes.formControl}
          style={{ ...sx.leftColumn }}
        >
          <FormLabel component="legend" style={{ ...sx.switchLabel }}>
            {t("cnc.dataSelector.columnsAsRows", "Use columns as rows")}
          </FormLabel>
          <FormGroup>
            <Switch
              checked={useColumnsAsRows}
              onChange={handleUseColumnsAsRows}
            />
          </FormGroup>
          <FormHelperText>
            {t(
              "cnc.dataSelector.processingOrientation",
              "processing orientation"
            )}
          </FormHelperText>
        </FormControl>

        <FormControl
          className={classes.formControl}
          style={{ ...sx.leftColumn }}
        >
          <FormLabel component="legend" style={{ ...sx.switchLabel }}>
            {t("cnc.dataSelector.useHalfData", "Use half data")}
          </FormLabel>
          <FormGroup>
            <Switch checked={useHalfData} onChange={handleUseHalfData} />
          </FormGroup>
          <FormHelperText>
            {t("cnc.dataSelector.fromCenter", "From center")}
          </FormHelperText>
        </FormControl>

        <FormControl
          className={classes.formControl}
          style={{ ...sx.leftColumn }}
        >
          <FormLabel component="legend" style={{ ...sx.switchLabel }}>
            {t("cnc.dataSelector.showTable", "Show table")}
          </FormLabel>
          <FormGroup>
            <Switch
              checked={showTable}
              onChange={this.handleBoolChange("showTable")}
            />
          </FormGroup>
          <FormHelperText>
            {t("cnc.dataSelector.generatedData", "Generated data")}
          </FormHelperText>
        </FormControl>

        {showTable ? (
          <React.Fragment>
            <FormControl className={classes.formControl}>
              <FormLabel component="legend" style={{ ...sx.switchLabel }}>
                {t("cnc.dataSelector.useHeights", "Use heights")}
              </FormLabel>
              <FormGroup>
                <Switch
                  checked={showHeights}
                  onChange={this.handleBoolChange("showHeights")}
                />
              </FormGroup>
              <FormHelperText>
                {showHeights
                  ? t("cnc.dataSelector.heightsMm", "Heights in mm")
                  : t("cnc.dataSelector.depthUnits", "Depth units")}
              </FormHelperText>
            </FormControl>

            <QRD2DDataTable
              dataset={dataset}
              meta={meta}
              stamp={currentStamp}
              rows={rows}
              columns={columns}
              useColumnsAsRows={useColumnsAsRows}
              useHalfData={useHalfData}
              minHeight={minHeight}
              maxHeight={maxHeight}
              useRounding={useRounding}
              // temporary
              //          useHeight={showHeights}

              showHeights={showHeights}
              rowSelected={rowSelected}
              columnSelected={columnSelected}
              onRowClick={this.setRowSelected.bind(this)}
              onColumnClick={this.setColumnSelected.bind(this)}
              primes={primes}
              squares={squares}
              api={api}
            />
          </React.Fragment>
        ) : null}

        <FormControl
          className={classes.formControl}
          style={{ ...sx.leftColumn }}
        >
          <TextField
            id="rowSelected"
            label={t("cnc.dataSelector.selectRow", "Select row")}
            value={rowSelected}
            error={!rowSelected}
            onChange={this.handleRowSelected("rowSelected").bind(this)}
            type="number"
            inputProps={{
              min: 1,
              max: rows,
            }}
          />
          <FormHelperText>{""}</FormHelperText>
        </FormControl>

        <FormControl
          className={classes.formControl}
          style={{ ...sx.leftColumn }}
        >
          <TextField
            id="columnSelected"
            label={t("cnc.dataSelector.selectColumn", "Select column")}
            value={columnSelected}
            error={!columnSelected}
            onChange={this.handleColumnSelected("columnSelected").bind(this)}
            type="number"
            inputProps={{
              min: 1,
              max: columns,
            }}
          />
          <FormHelperText>{""}</FormHelperText>
        </FormControl>

        {!false && showTable ? (
          <Fragment>
            <QRDStats
              calculated={calculated}
              blockWidth={blockWidth}
              showHeights={showHeights}
              primes={primes}
              api={api}
            />
            <BOM
              calculated={calculated}
              useColumnsAsRows={useColumnsAsRows}
              useHalfData={useHalfData}
              blockWidth={blockWidth}
              primes={primes}
              api={api}
            />
          </Fragment>
        ) : null}
      </div>
    );
  }
}

QRD2DDataSelector.propTypes = {
  //  periodLength: PropTypes.number.isRequired,
  calculated: PropTypes.object.isRequired,
  primes: PropTypes.object.isRequired,

  classes: PropTypes.object.isRequired,
  api: PropTypes.object.isRequired,
  //  setResult: PropTypes.func.isRequired,
};

// export default withStyles()(withTheme(QRD2DDataSelector));
export default withTranslation("diffuser")(
  withTheme(withStyles(styles)(QRD2DDataSelector))
);
