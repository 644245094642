import React from "react";
import styled from "styled-components";
import { withTranslation } from "react-i18next";

const StyledBlock = styled.div`
  display: inline-block;
  margin: 0 6px;
  color: red;
  /* float: left; */
`;

const StyledButton = styled.div`
  display: inline-block;
  font-size: 1rem;
  padding: 0 4px;
  text-align: center;
  cursor: pointer;
`;

const LangSwitcher = (props) => {
  const handleChangeLang = (lang, e) => {
    //    e.preventDefault();
    console.log("__App__LangSwitcher, lang:", lang);
    props.i18n.changeLanguage(lang);
    //    this.setState({
    //      language: lang
    //    });
  };
  return (
    <StyledBlock>
      <StyledButton onClick={() => handleChangeLang("pl")}>PL</StyledButton>
      <StyledButton onClick={() => handleChangeLang("en")}>EN</StyledButton>
    </StyledBlock>
  );
};

export default withTranslation()(LangSwitcher);
