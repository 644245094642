/* eslint-disable react/prop-types */
import React from 'react';
import PropTypes from 'prop-types';

//import { CirclePath } from './RouterPaths'
import { TopPath, BottomPath, HolesPath } from './SVGRouteBlockBar';

const Holes = (props) => {
  const { holePoints, holeDiam } = props;
  const sx = {
    fill: 'red',
  };
  const radius = holeDiam / 2;

  return (
    <g id="Holes" style={sx}>
      {holePoints.map((p, k) => (
        <ellipse key={k} cx={p[0]} cy={p[1]} rx={radius} ry={radius} />
      ))}
    </g>
  );
};

const SvgText = (props) => {
  const {
    textPaths,
    baseTextMargin,
    baseTextBottom,
    style,
    //    text,
  } = props;

  return (
    <g
      id="Descr"
      transform={`translate(${baseTextMargin},-${baseTextBottom}) scale(1,1)`}
    >
      <path style={{ ...style }} d={textPaths} />
    </g>
  );
};

const SVGBlockBar = (props) => {
  const {
    blockPoints,

    drawHoles,
    holePoints,
    holeDiam,

    drawText,
    textPaths,
    baseTextMargin,
    baseTextBottom,

    generateToolPaths,
    toolPathsOnly,
    toolDiameter,
    strokeWidth,

    maxHeight,
    baseHeight,
    //    baseSideWidth,
    //    baseSideHeight,
  } = props;

  const sx = {
    drawing: {
      width: '100%',
      height: '50%',
      maxHeight: 600,
    },
    toolPath: {
      stroke: 'green',
      strokeWidth: strokeWidth / 2 + 'mm',
      strokeLinecap: 'round',
      strokeLinejoin: 'round',
      fill: 'none',
    },
  };

  //  if(!blockPoints.length) return ( <span>PROCESSING</span> )

  // quick test
  const blockWidth = Math.max(
    blockPoints[blockPoints.length - 1][0],
    blockPoints[blockPoints.length - 3][0]
  );
  //  const blockHeight = blockPoints.reduce( (acc, curr) => { return acc > curr[1] ? acc : curr[1] } ) + baseHeight;
  // constant high for all rows
  const blockHeight = maxHeight + baseHeight;

  const svgMargin = 10;
  const drawWidth = blockWidth + svgMargin * 2;
  const drawHeight = blockHeight + svgMargin * 2;

  // for closing SVG path
  const firstPoint = blockPoints[0].join(',');

  return (
    <svg
      ref={props.svgRef}
      style={sx.drawing}
      viewBox={`-${svgMargin} -${svgMargin} ${drawWidth} ${drawHeight}`}
      width={`${drawWidth}mm`}
      height={`${drawHeight}mm`}
    >
      <g
        id="AllBlocks"
        transform={`
                         scale(1, -1) 
        translate(0, -${blockHeight})
      `}
      >
        <g id="drawing">
          {!toolPathsOnly && (
            <rect
              id={'reference_' + blockHeight}
              x={-svgMargin}
              y={0}
              width={svgMargin / 2}
              height={blockHeight}
              fill="lightgrey"
            />
          )}
          {!toolPathsOnly && (
            <polyline
              id="element"
              points={blockPoints.join(' ') + ' ' + firstPoint}
              fill="lightgrey"
              stroke="grey"
              strokeWidth="0.1mm"
            ></polyline>
          )}

          {!toolPathsOnly && drawHoles && (
            <Holes id="holes" holePoints={holePoints} holeDiam={holeDiam} />
          )}
        </g>

        {generateToolPaths && (
          <g id="routing">
            <g id="top path" style={{ ...sx.toolPath }}>
              <TopPath blockPoints={blockPoints} toolDiameter={toolDiameter} />
            </g>

            <g id="drill holes" style={{ ...sx.toolPath }}>
              <HolesPath
                holePoints={holePoints}
                holeDiam={holeDiam}
                toolDiameter={toolDiameter}
              />
            </g>

            {drawText && (
              <g
                id="Description"
                transform="
                translate(0, 0)
              "
              >
                <g
                  transform="
                  scale(1,-1)
                "
                >
                  <SvgText
                    textPaths={textPaths}
                    baseTextMargin={baseTextMargin}
                    baseTextBottom={baseTextBottom}
                    style={sx.toolPath}
                  />
                </g>
              </g>
            )}

            <g id="bottom path" style={{ ...sx.toolPath }}>
              <BottomPath
                blockPoints={blockPoints}
                toolDiameter={toolDiameter}
              />
            </g>
          </g>
        )}
      </g>
    </svg>
  );
};

SVGBlockBar.propTypes = {
  blockPoints: PropTypes.array,
};

export default SVGBlockBar;
