import React, { Fragment } from 'react'
import PropTypes from 'prop-types';

import { CirclePath } from './SVGRouterPaths'

export const TopPath = ( props ) =>  {
  const { 
    blockPoints,
    toolDiameter,
  } = props

  const radius = toolDiameter / 2    // tool path offset  
  let points = []

  // tool entry drilling point - start routing outside of path
  // points.push( [ -2*toolDiameter, -radius ] )
  // points.push( [ 0, -radius ] )

  let i = 0
  
  const pathStep = ( i ) => {
  
    let currPoint = blockPoints[i] 
    let nextPoint = blockPoints[i+1]      

    if( nextPoint[0] === currPoint[0] ) { 
      if( nextPoint[1] < currPoint[1] ) { 
        points.push( [ currPoint[0]+radius, currPoint[1]+radius ] ) // around corner
      }
    }
    if( nextPoint[1] < currPoint[1] ) { 
      points.push( [ currPoint[0]+radius, currPoint[1]+radius ] )
    }
    if( nextPoint[1] >= currPoint[1] ) { 
      points.push( [ nextPoint[0]-radius, nextPoint[1]+radius ] )
    }
     
    if( nextPoint[0] === currPoint[0] ) { 
      if( nextPoint[1] < currPoint[1] ) { 
                                        
        // going left? pull down
        let pull = false
        if( points[points.length-4][0] > points[points.length-3][0] ) {
          pull = true
        }
        let before = points.pop()
        
        points.pop()   
        let earlier = points.pop()
        
        if( pull ) {
          earlier[0]+=toolDiameter
          points.push(earlier)
          before[1]-=toolDiameter
        }
        points.push(before)
        
        if( pull ) {       
          if( nextPoint[1]-toolDiameter < -radius ) {
            points.push( [ nextPoint[0]+radius, -radius ] )
          } else {
            points.push( [ nextPoint[0]+radius, nextPoint[1]-toolDiameter ] )
          }
        } else { 
          points.push( [ nextPoint[0]+radius, nextPoint[1]+radius ] )
        }
      }
    }
    if( nextPoint[0] < currPoint[0] ) { 
      let current = points.pop()
      let before = points.pop()
      current[1]-=toolDiameter
//      before[1]-=toolDiameter
      before[1]=current[1]
      points.push(before)    
      points.push(current)    
    }
  }
 
  points.push( [ blockPoints[i][0]-radius, blockPoints[i][1]-radius ] )
  for( i; i<blockPoints.length-1; i++ ) {
    pathStep(i)
  }
  points.push( [ blockPoints[i][0]+radius, blockPoints[i][1]-radius ] )
  // points.push( [ blockPoints[i][0]-toolDiameter, blockPoints[i][1]-radius ] )
  // points.push( [ blockPoints[i][0]-radius, blockPoints[i][1]-radius ] )
                                 
  let path=""
  let start=points.splice(0,1)
  path +="M"+start.join(',')
  path += points.reduce( (acc, val) => {return acc + "L"+val.join(',') }, "" )

  return (                  
    <path id="top shape" 
      d={path}
    />
  ) 
}
                 
export const BottomPath = ( props ) =>  {
  const { 
    blockPoints,
    toolDiameter,
    } = props
  const radius = toolDiameter / 2    // tool path offset  
  let startPoint=blockPoints[0]
  let endPoint=blockPoints[blockPoints.length-1]
  startPoint[1]-=radius
  endPoint[1]-=radius
  // move left if entry drilling point used
  // startPoint[0]-=toolDiameter
  // endPoint[0]-=toolDiameter          
  startPoint[0]-=radius
  endPoint[0]+=radius
  let path = ""
  path +="M"+startPoint.join(',')
  path +="L"+endPoint.join(',')
  return (                  
    <path id="bottom shape" 
      d={path}
    />
  ) 
}

                                                           
export const HolesPath = (props) => {
  const { 
    holePoints,
    holeDiam,
    toolDiameter,
  } = props;                        
  const toolRadius = toolDiameter/2 
  // hole radius reduced by tool radius - not using RouteCirclePath toolDiameter based offset 
  let radius = holeDiam/2 - toolRadius   
  radius = +(Math.round(radius + "e+4")  + "e-4");
                            
  const maxOffset = 2*radius - toolDiameter > radius ? 2*radius - toolDiameter : radius
  // common precalculations
  let sideOffset = toolDiameter * 2
  if( sideOffset > maxOffset ) sideOffset = maxOffset  // for small holes
  sideOffset = +(Math.round(sideOffset + "e+4")  + "e-4");
                                    

  return ( 
    <Fragment>
      { holePoints.slice().reverse().map( (p, k) => (
        <Fragment key={k}>
          {false && <ellipse key={k} cx={p[0]} cy={p[1]} rx={radius} ry={radius} />}
          <CirclePath x={p[0]} y={p[1]} r={radius} entryOffset={sideOffset} />
        </Fragment>
        )
      )}
    </Fragment>
  );
}


export const commonPropTypes = {
  blockPoints: PropTypes.array,
  toolDiameter: PropTypes.number,
}            

TopPath.propTypes = commonPropTypes 
BottomPath.propTypes = commonPropTypes 
HolesPath.propTypes = {
  holePoints: PropTypes.array,
  holeDiam: PropTypes.number,
  toolDiameter: PropTypes.number,
}
   

/*
module.exports = {
  TopPath: TopPath,
  BottomPath: BottomPath,
  HolesPath: HolesPath,
}
*/