import React from 'react';
import PropTypes from 'prop-types';
//import { withStyles, withTheme } from 'material-ui/styles'
//import Typography from 'material-ui/Typography';
//import TextField from 'material-ui/TextField';
//import Select from 'material-ui/Select';                      
//import Switch from 'material-ui/Switch';
//import { InputLabel } from 'material-ui/Input';
//import { MenuItem } from 'material-ui/Menu';
//import { FormLabel, FormGroup, FormControl, FormHelperText } from 'material-ui/Form';
//import QRD2DDataTable from './QRD2DDataTable'

//import { getPrimesLimit } from '../data/prime';         
import { ElementsByHeight, TotalBlocksHeight, TotalBlocksVolume } from './QRDStats'

import './BOM.css';


const BOM = (props) => {
  const {
    calculated,

    blockWidth,

    api,
  } = props
  const { dataset, meta } = calculated
  const periodLength = meta.period 
  const {     
    rows, 
    useHeight,
    useRounding,
   } = meta.params
   
  const currentStamp = calculated.stamp 
  const bomElements = !!meta.stats ? meta.stats.bomElements : [] 
  
//  const squares = props.primes.squares;
//  const formatNumber = api.formatPartNumber
  const maxNumLength = (''+periodLength).length
  const formatNumber = api.formatNumberLeading

  const commonHeights = api.getHeights()
  const heights = commonHeights.dataset 

  return (
    <div className="billOfMaterial" >
      <div className="bom-title">
        Configuration <b>{currentStamp}</b><br />
      </div>
      
      <ElementsByHeight           
        bomElements={bomElements}
        useHeight={true}
        useRounding={useRounding}
//        api={api}
        heights={heights} 
      />         

      <span>POSSIBLY NOT VALID</span>
      <TotalBlocksHeight
        bomElements={bomElements}
        meta={meta}
        heights={heights}
      />               
      
      <TotalBlocksVolume
        meta={meta}
        blockWidth={blockWidth}
      />
      
      <div key={'E'+currentStamp} >
        <div>    
          <b>Bill of elements for each row</b> (sorted) in { useHeight ? "mm" : "depth units" }:
        </div>    
        <div className="mono lefty-on-right" >
          <div className="bom-list">    
          {dataset.map( (n, i) => { 
            return (
            <span key={i}><b>row {formatNumber(rows-i,maxNumLength,'\xa0')}</b>: {n.slice().filter(v=> v>-1 ).sort( (a,b) => a-b ).join(',\xa0')}
            <br />
            </span>
          ); } )}
          </div>    
        </div>    
      </div>    
    </div>
  )
}

BOM.propTypes = {
//  classes: PropTypes.object.isRequired,
//  periodLength: PropTypes.number.isRequired,
  calculated: PropTypes.object.isRequired,
  
  api: PropTypes.object.isRequired,
};


export default BOM;
