/* eslint-disable react/prop-types */
import React, { Fragment } from "react";
import PropTypes from "prop-types";
import {
  // useTranslation,
  withTranslation,
  // WithTranslation,
} from "react-i18next";
import { withStyles, withTheme } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
//import TextField from 'material-ui/TextField';
//import Select from 'material-ui/Select';
import Switch from "@material-ui/core/Switch";
import Button from "@material-ui/core/Button";
//import { InputLabel } from 'material-ui/Input';
//import { MenuItem } from 'material-ui/Menu';
import { FormLabel } from "@material-ui/core";
import { FormGroup } from "@material-ui/core";
import { FormControl } from "@material-ui/core";
import { FormHelperText } from "@material-ui/core";

import SVGTable from "./SVGTable";

import "./QRDConverter.css";

const DumpRefHTML = (props) => {
  const sx = {
    fontSize: "0.7em",
    lineHeight: "1.1em",
    display: "inline-block",
  };
  return (
    <code style={sx}>
      {!!props.refValue ? props.refValue.outerHTML : "<span>BAD</span>"}
    </code>
  );
};

const styles = (theme) => ({
  root: {
    width: "100%",
    // maxWidth: "36ch",
    backgroundColor: theme.palette.background.paper,
  },
  leftColumn: {
    width: 100,
  },
  blockFormTitle: {
    marginBottom: 6,
    marginTop: 20,
  },
});

class QRD2DMapChart extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      showTableMap: false,
      showHeights: true,
    };
    this.svgElement = null;
    this.downloadable = null;
    this.downloadableUpdateCallbacks = [];

    //    this.downloadableReference = this.downloadableReference.bind(this)
  }

  handleBoolChange = (name) => (event) => {
    this.setState({ [name]: event.target.checked });
  };

  downloadableReference = (el) => {
    //    console.log("DWNLD_REF ",el);
    this.svgElement = el;
    this.downloadable = el;
    if (!!el) {
      //      console.log("DWNLD_REF ready ", el.outerHTML )
      setTimeout(() => {
        //        if( null !== el ) {
        //          console.log("DWNLD_REF delayed ", el.outerHTML )
        //        console.log("REF ready ", el )
        //        this.setState({
        //          downloadReady: !!el
        //        })
        this.notifyDownloadableDependants();
        //        }
      }, 0);
    }
  };

  registerDownloadableUpdateCallback = (clbk) => {
    //    console.log('registerCallback')
    this.downloadableUpdateCallbacks.push(clbk);
  };

  notifyDownloadableDependants = () => {
    //    console.log('notifyDownloadableDependants')
    this.downloadableUpdateCallbacks.map((clbk) => clbk(this.svgElement));
  };

  handleDownload = (event, name) => {
    //  debugger
    event.preventDefault();
    this.props.api.handleSVGDownload(
      name,
      "generated_MAP_" //+ this.formatPartNumber(this.state.rowSelected)
    );
  };

  render() {
    const {
      blockWidth,
      useHalfData,
      //      params,
      classes,
      api,
      t,
    } = this.props;

    const handleUseHalfData = this.props.api.handleUseHalfData;

    //    console.log("this.svgElement",this.svgElement)
    //    debugger
    /*    
    const { 
//      keepSquared,
      columns,
      rows,
//      keepCentered,
//      columnOffset,
//      rowOffset, 
//      negative,       
      useHeight,  
      minHeight,     
      maxHeight,     
      useRounding,
    } = params         
    
    const { primes, squares } = this.props.primes;      
//    const squares = this.squares;
*/
    //    debugger
    const { showTableMap, showHeights } = this.state;

    //    const dataset = this.props.calculated.dataset
    // const { dataset/*, meta*/ } = this.props.calculated
    let dataset = this.props.calculated.dataset;
    if (useHalfData) {
      const halfIdx = Math.floor(dataset.length / 2);
      console.log(" MapChart halfIdx", halfIdx);
      dataset = dataset.slice(halfIdx);
    }
    //    const currentStamp = this.props.calculated.stamp

    //    const heights = api.getHeights().
    let heights = [];
    if (showHeights) {
      const commonHeights = api.getHeights();
      if (!commonHeights)
        return <span>{t("chart2D.waitProcessing", "PROCESSING")}</span>;
      heights = commonHeights.dataset;
    }
    //    console.log( 'QRDMAP heights',heights )
    //    console.log( 'QRDMAP render props',this.props,'heights',heights )

    const sx = {
      blockFormTitle: {
        marginBottom: 6,
        marginTop: 20,
      },
      switchLabel: {
        //        marginTop: 10,
        //        marginBottom: 8,
        //        position: 'relative',
        //        top: 13,
      },
      infoColumn: {
        display: "inline-block",
        verticalAlign: "middle",
        maxWidth: 250,
      },
      leftColumn: {
        width: 100,
      },
      wideColumn: {
        maxWidth: 200,
      },
      cell: {
        display: "inline",
        padding: 12,
      },
      cellScrolled: {
        fontSize: 11,
        overflowX: "auto",
        overflowY: "auto",
        display: "inline-block",
        verticalAlign: "middle",
        maxWidth: 800,
        maxHeight: 600,
        padding: 4,
      },
    };

    //    debugger
    return (
      <div>
        <Typography variant="h5" style={{ ...sx.blockFormTitle }}>
          {t("chart2D.title", "2D chart")}
        </Typography>

        <FormControl
          className={classes.formControl}
          style={{ ...sx.leftColumn }}
        >
          <FormLabel component="legend" style={{ ...sx.switchLabel }}>
            {t("chart2D.showMap", "Show table map")}
          </FormLabel>
          <FormGroup>
            <Switch
              checked={showTableMap}
              onChange={this.handleBoolChange("showTableMap")}
            />
          </FormGroup>
          <FormHelperText>
            {t("chart2D.heightsChart", "Heights chart")}
          </FormHelperText>
        </FormControl>

        {showTableMap && (
          <Fragment>
            <FormControl className={classes.formControl}>
              <FormLabel component="legend" style={{ ...sx.switchLabel }}>
                {t("chart2D.showHeights", "Show heights")}
              </FormLabel>
              <FormGroup>
                <Switch
                  checked={showHeights}
                  onChange={this.handleBoolChange("showHeights")}
                />
              </FormGroup>
              <FormHelperText>
                {showHeights
                  ? t("chart2D.heightsInMm", "Heights in mm")
                  : t("chart2D.depthUnits", "Depth units")}
              </FormHelperText>
            </FormControl>

            <FormControl
              className={classes.formControl}
              style={{ ...sx.leftColumn }}
            >
              <FormLabel component="legend" style={{ ...sx.switchLabel }}>
                {t("chart2D.useHalfData", "Use half data")}
              </FormLabel>
              <FormGroup>
                <Switch checked={useHalfData} onChange={handleUseHalfData} />
              </FormGroup>
              <FormHelperText>
                {t("chart2D.fromCenter", "From center")}
              </FormHelperText>
            </FormControl>

            <SVGTable
              dataset={dataset}
              cellWidth={blockWidth}
              cellHeight={blockWidth}
              //            rows={4}
              //            columns={6}

              // getter - can be used to procedural fill
              // getValue={(x,y,v)=>y+10*x}
              // ... or to calculate derived value from original (from dataset)
              // f.e. getValue={(x,y,v)=>v+10*x}
              // undefined same as getValue={(x,y,v)=>v}

              // combined getter (using indexed array) + decorator
              getLabel={(x, y, v) =>
                showHeights && undefined !== heights[v]
                  ? heights[v].toFixed(1) + " mm"
                  : undefined
              }
              showLabels={showHeights}
              svgRef={this.downloadableReference.bind(this)} //el => { console.log("REF ",el); this.svgElement = el } }
            />

            <Button
              disableFocusRipple
              fullWidth
              color="default"
              size="medium"
              variant="outlined"
              onClick={(e) => {
                if (this.svgElement) this.handleDownload(e, this.svgElement);
              }}
            >
              {t("chart2D.dowloadSVG", "DOWLOAD SVG")}
            </Button>

            {window.showComments && (
              <Fragment>
                <br />
                <FreshReferencedValue
                  //              refValue={this.svgElement}
                  registerCallback={this.registerDownloadableUpdateCallback}
                  t={t}
                >
                  <DumpRefHTML refValue={this.svgElement} />
                </FreshReferencedValue>
              </Fragment>
            )}
          </Fragment>
        )}
      </div>
    );
  }
}

class FreshReferencedValue extends React.Component {
  componentDidMount() {
    //    console.log("FRESH CDM")
    this.props.registerCallback((value) => {
      this.refValue = value;
      this.forceUpdate();
    });
  }
  render() {
    const { t } = this.props;
    let refValue = this.props.refValue;
    if (!!this.refValue) refValue = this.refValue;
    //    console.log("FRESH_REF render ",refValue ? refValue.outerHTML: 'NULL' )
    //    console.log("FRESH_REF render")
    return !!refValue ? (
      React.cloneElement(this.props.children, { refValue: refValue })
    ) : (
      <span>{t("chart2D.notValid", "NOT VALID")}</span>
    );
  }
}
FreshReferencedValue.propTypes = {
  refValue: PropTypes.object,
};

QRD2DMapChart.propTypes = {
  classes: PropTypes.object.isRequired,
  //  setResult: PropTypes.func.isRequired,
};

//export default withStyles()(withTheme(QRD2DMapChart));
// export default withTheme(withStyles(styles)(QRD2DMapChart));
export default withTranslation("diffuser")(
  withTheme(withStyles(styles)(QRD2DMapChart))
);
