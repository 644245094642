/* eslint-disable react/prop-types */
import React from "react";
import PropTypes from "prop-types";
import {
  // useTranslation,
  withTranslation,
  // WithTranslation,
} from "react-i18next";
import { withTheme, withStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import Select from "@material-ui/core/Select";
import Switch from "@material-ui/core/Switch";
import { InputLabel } from "@material-ui/core";
import { MenuItem } from "@material-ui/core";
import { FormLabel } from "@material-ui/core";
import { FormGroup } from "@material-ui/core";
import { FormControl } from "@material-ui/core";
import { FormHelperText } from "@material-ui/core";

//import QRD2DDataTable from './QRD2DDataTable';

import "./QRDConverter.css";

// useStyles = makeStyles((theme) => ({
const styles = (theme) => ({
  root: {
    width: "100%",
    // maxWidth: "36ch",
    backgroundColor: theme.palette.background.paper,
  },
  leftColumn: {
    width: 100,
  },
  blockFormTitle: {
    marginBottom: 6,
    marginTop: 20,
  },
});

class QRD2DGenerator extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      showTable: false,
      showHeights: false,
    };

    //    this.primes = this.props.primes.primes;
    //    this.squares = this.props.primes.squares;
    this.generateArray = this.generateArray.bind(this);
    this.delayed = null;
  }

  generateRow(
    period,
    horizontalOffset,
    verticalOffset,
    negative = true,
    margin = 0
  ) {
    const squares = this.props.primes.squares;
    const negation = !!negative;
    let result = [];
    for (
      let i = horizontalOffset + margin;
      i < period + horizontalOffset - margin;
      i++
    ) {
      let value = negation
        ? period - ((squares[i] + squares[verticalOffset]) % period) - 1
        : (squares[i] + squares[verticalOffset]) % period;
      result.push(value);
    }
    return result;
  }

  generateArray = () => {
    const {
      keepSquared,
      rows,
      columns,
      //      minHeight,
      //      maxHeight,
      rowOffset,
      columnOffset,
      negative,
      //      useHeight,
      //      useRounding,
    } = this.props.params;

    // periodLength can be out of sync
    const periodLength = rows > columns ? rows : columns;

    const setResult = this.props.api.setResult;

    console.log("GEN Array");

    const currentStamp = this.props.calculated.stamp;
    //    const stamp = ""+keepSquared+columns+"_"+rows+"_"+columnOffset+"_"+rowOffset+negative+useHeight+minHeight+'_'+maxHeight+useRounding
    const stamp =
      "" +
      keepSquared +
      columns +
      "_" +
      rows +
      "_" +
      columnOffset +
      "_" +
      rowOffset +
      negative;
    if (currentStamp === stamp) return;
    console.log("GEN recalculation needed", currentStamp, " <> ", stamp);

    // conversion table  depth units to mm
    /*        
    const unit = (maxHeight-minHeight)/(periodLength-1);
    let heights = [];
    for( let i = 0; i < periodLength; i++ ) {
        if (useRounding) {                        
          heights.push( Math.round(i * unit+minHeight) )
        } else {
          heights.push( +(Math.round((i * unit+minHeight) + "e+2")  + "e-2") );
        }
    }
*/
    let params = [];
    let calculated = [];
    let meta = {};
    let stats = {};
    let totalHeight = 0;
    const statsCallback = (acc, val) => {
      totalHeight += val;
      return val > acc ? val : acc;
    };
    const rowMiddle = Math.floor(rows / 2);
    const columnMiddle = Math.ceil(periodLength / 2);
    const margin = periodLength !== columns ? (periodLength - columns) / 2 : 0;
    let hOffset = keepSquared ? columnOffset : columnMiddle + columnOffset;
    hOffset = hOffset < 0 ? -hOffset : hOffset;
    for (let i = 0; i < rows; i++) {
      let vOffset = keepSquared ? i + rowOffset : i - rowMiddle + rowOffset;
      vOffset = vOffset < 0 ? -vOffset : vOffset;
      //      let row = this.generateRow ( columns, columnOffset, vOffset, negative )
      let row = this.generateRow(
        periodLength,
        hOffset,
        vOffset,
        negative,
        margin
      );
      let max = row.reduce((acc, val) => statsCallback(acc, val), 0);
      row.map((v) => {
        stats[v] = !!stats[v] ? stats[v] + 1 : 1;
        return 1;
      });
      calculated.push(row);
      params.push({
        period: periodLength,
        //        horizontalOffset: columnOffset,
        horizontalOffset: hOffset,
        verticalOffset: vOffset,
        maxValue: max,
        //        elements: row.filter(v=> !!v ).sort( (a,b) => a-b ),
      });
    }
    meta.rowData = params;
    meta.period = periodLength;
    meta.margin = margin;
    meta.params = this.props.params;

    meta.stats = {
      // bomElements mapping object ( keys=height values=amount of this height )
      bomElements: stats,
      // deprecated - separation deeps from heights
      totalHeight: totalHeight,
    };
    // stored in parent
    setResult({
      dataset: calculated,
      meta: meta,
      //      heights: heights,
      //      totalHeight: totalHeight,
      stamp: stamp,
      valid: true,
    });
  };

  handleBoolChange = (name) => (event) => {
    this.setState({ [name]: event.target.checked });
  };

  setRowSelected = (value) => {
    // local save value
    if (value > -1 && value < this.props.rows + 1) {
      this.setState({
        rowSelected: value,
        columnSelected: 0,
      });
    }
    let result = {
      rowSelected: value,
    };
    // parent's handler
    this.props.handleRowSelect(result);
  };

  setColumnSelected = (value) => {
    // local save value
    if (value > -1 && value < this.props.columns + 1) {
      this.setState({
        rowSelected: 0,
        columnSelected: value,
      });
    }
    let result = {
      columnSelected: value,
    };
    // parent's handler
    this.props.handleColumnSelect(result);
  };

  handleRowSelected = () => (event) => {
    this.setRowSelected(+event.target.value);
  };

  handleColumnSelected = () => (event) => {
    this.setColumnSelected(+event.target.value);
  };

  componentDidUpdate() {
    //    console.log("GEN CDU");
    //    if( this.state.rowSelected !== this.props.rowSelected ) {
    //      this.setState({ rowSelected: this.props.rowSelected });
    //    }
    const {
      rows,
      columns,
      //      minHeight,
      //      maxHeight,
      keepSquared,
      rowOffset,
      columnOffset,
      //      keepCentered,
      negative,
      //      useRounding,
      //      useHeight,
    } = this.props.params;

    const currentStamp = this.props.calculated.stamp;
    //    const stamp = ""+keepSquared+columns+"_"+rows+"_"+columnOffset+"_"+rowOffset+negative+useHeight+minHeight+'_'+maxHeight+useRounding
    const stamp =
      "" +
      keepSquared +
      columns +
      "_" +
      rows +
      "_" +
      columnOffset +
      "_" +
      rowOffset +
      negative;
    if (currentStamp !== stamp) {
      //      console.log("GEN CDU genArray");

      //      this.generateArray();

      // throttling
      // parameters/state can change before executing recalculation
      clearTimeout(this.delayed);
      this.delayed = setTimeout(this.generateArray, 2500);
    }
  }

  render() {
    const {
      params,
      //      columnSelected,
      //      rowSelected,

      classes,
      api,
      t,
    } = this.props;

    const {
      keepSquared,
      columns,
      rows,
      keepCentered,
      columnOffset,
      rowOffset,
      negative,
      //      useHeight,
      //      minHeight,
      //      maxHeight,
      //      useRounding,
    } = params;

    const { primes, squares } = this.props.primes;
    //    const squares = this.squares;
    //    const { showTable, showHeights } = this.state;

    //    const dataset = this.props.calculated.dataset
    const { dataset, meta } = this.props.calculated;
    //    const currentStamp = this.props.calculated.stamp

    //    const heights = api.getHeights().

    // const sx = {
    //   blockFormTitle: {
    //     marginBottom: 6,
    //     marginTop: 20,
    //   },
    //   switchLabel: {
    //     //        marginTop: 10,
    //     //        marginBottom: 8,
    //     //        position: 'relative',
    //     //        top: 13,
    //   },
    //   infoColumn: {
    //     display: "inline-block",
    //     verticalAlign: "middle",
    //     maxWidth: 250,
    //   },
    //   leftColumn: {
    //     width: 100,
    //   },
    //   wideColumn: {
    //     maxWidth: 200,
    //   },
    //   cell: {
    //     display: "inline",
    //     padding: 12,
    //   },
    //   cellScrolled: {
    //     fontSize: 11,
    //     overflowX: "auto",
    //     overflowY: "auto",
    //     display: "inline-block",
    //     verticalAlign: "middle",
    //     maxWidth: 800,
    //     maxHeight: 600,
    //     padding: 4,
    //   },
    // };
    /*
    // 'alphas' between base and limit as 'procents'    
    const colorStep = (n, limit, base=20) => {
      let unit = useHeight ? (limit - base)/(maxHeight-minHeight+1) : (limit - base)/(columns-1);
      let value = (n-minHeight)*unit+base;
      return (value/100).toFixed(2); 
    }
    
    const hideScrolling = 25*rows > 590 ? { overflowY:'auto' } : { overflowY:'hidden' }
*/
    //    console.log("GEN render")

    // const classes = this.useStyles();

    return (
      <div>
        <Typography variant="h5" className={classes.blockFormTitle}>
          {t("settings.geometry.title", "Data 'geometry' - generator")}
        </Typography>

        <FormControl className={classes.formControl}>
          <FormLabel component="legend" className={classes.switchLabel}>
            {t("settings.geometry.keepSquared", "Keep squared")}
          </FormLabel>
          <FormGroup>
            <Switch
              checked={keepSquared}
              onChange={api.handleSquared}
              value="keepSquared"
            />
          </FormGroup>
        </FormControl>

        <FormControl className={`${classes.formControl} ${classes.wideColumn}`}>
          <InputLabel htmlFor="size-simple">
            {t("settings.geometry.width", "Width")}
          </InputLabel>
          <Select
            value={columns}
            onChange={api.handleColumns}
            inputProps={{
              name: "size",
              id: "size-simple__",
            }}
          >
            {primes.map((n) => (
              <MenuItem key={n} value={n}>
                {n}
              </MenuItem>
            ))}
            ;
          </Select>
          <FormHelperText>
            {t("settings.geometry.columns", "Columns")}
          </FormHelperText>
        </FormControl>

        <FormControl
          className={`${classes.formControl} ${classes.wideColumn}`}
          disabled={keepSquared}
        >
          <InputLabel htmlFor="size-simple">
            {t("settings.geometry.height", "Height")}
          </InputLabel>
          <Select
            value={rows}
            onChange={api.handleRows}
            inputProps={{
              name: "size",
              id: "size-simple__",
            }}
          >
            {primes.map((n) => (
              <MenuItem key={n} value={n}>
                {n}
              </MenuItem>
            ))}
            ;
          </Select>
          <FormHelperText>{t("settings.geometry.rows", "Rows")}</FormHelperText>
        </FormControl>

        <FormControl className={classes.formControl}>
          <FormLabel component="legend" className={classes.switchLabel}>
            {t("settings.geometry.keepSymmetry", "Keep centered")}
          </FormLabel>
          <FormGroup>
            <Switch
              checked={keepCentered}
              onChange={api.handleCentered}
              value="keepCentered"
            />
          </FormGroup>
        </FormControl>

        <FormControl
          className={`${classes.formControl} ${classes.leftColumn}`}
          disabled={keepCentered}
        >
          <TextField
            disabled={keepCentered}
            id="horizOffset"
            label={t("settings.geometry.horizontalOffset", "Horizontal offset")}
            value={columnOffset}
            onChange={api.handleColumnOffset}
            type="number"
            inputProps={{
              min: keepSquared ? -1 : -Math.ceil(columns / 2),
              max: keepSquared ? columns : Math.ceil(columns / 2),
            }}
          />
          <FormHelperText>
            {keepSquared
              ? ""
              : t(
                  "settings.geometry.fromHorizontalCenter",
                  "From horizontal center"
                )}
          </FormHelperText>
        </FormControl>

        <FormControl
          className={`${classes.formControl} ${classes.leftColumn}`}
          disabled={keepCentered}
        >
          <TextField
            disabled={keepCentered}
            id="vertOffset"
            label={t("settings.geometry.verticalOffset", "Vertical offset")}
            value={rowOffset}
            onChange={api.handleRowOffset}
            type="number"
            inputProps={{
              min: keepSquared ? -1 : -Math.ceil(rows / 2),
              max: keepSquared ? rows : Math.ceil(rows / 2),
            }}
          />
          <FormHelperText>
            {keepSquared
              ? ""
              : t(
                  "settings.geometry.fromVerticalMiddle",
                  "From vertical middle"
                )}
          </FormHelperText>
        </FormControl>

        <FormControl className={classes.formControl}>
          <FormLabel component="legend" className={classes.switchLabel}>
            {t("settings.geometry.negativ", "Negative")}
          </FormLabel>
          <FormGroup>
            <Switch checked={negative} onChange={api.handleNegative} />
          </FormGroup>
          <FormHelperText>
            {negative
              ? t("settings.geometry.blockHeights", "Block heights")
              : t("settings.geometry.wellDepths", "Well depths")}
          </FormHelperText>
        </FormControl>

        {window.showComments && (
          <React.Fragment>
            <hr />

            <br />
            <div className={classes.cellScrolled}>
              {dataset.map((n, i) => {
                let info = meta.rowData[i];
                let d = n.join(", ");
                return (
                  <div
                    key={i}
                    title={
                      "N" +
                      info.period +
                      "+" +
                      (squares[info.verticalOffset] % info.period) +
                      "," +
                      info.horizontalOffset +
                      ""
                    }
                  >
                    {d}
                  </div>
                );
              })}
            </div>
            <br />
            {/*calculated.join(', ')*/}
            <br />
          </React.Fragment>
        )}
      </div>
    );
  }
}

QRD2DGenerator.propTypes = {
  classes: PropTypes.object.isRequired,
  //  setResult: PropTypes.func.isRequired,
};

// export default withStyles()(withTheme(QRD2DGenerator));
// export default withStyles(styles)(withTheme(QRD2DGenerator));
// export default withTheme(withStyles(styles)(QRD2DGenerator));
export default withTranslation("diffuser")(
  withTheme(withStyles(styles)(QRD2DGenerator))
);
// export default withTheme(QRD2DGenerator);
