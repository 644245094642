/* eslint-disable react/prop-types */
import React from "react";
import PropTypes from "prop-types";
import {
  // useTranslation,
  withTranslation,
  // WithTranslation,
} from "react-i18next";
import { withTheme, makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
//import Select from 'material-ui/Select';
import Switch from "@material-ui/core/Switch";
//import { InputLabel } from 'material-ui/Input';
//import { MenuItem } from 'material-ui/Menu';
import { FormLabel } from "@material-ui/core";
import { FormGroup } from "@material-ui/core";
import { FormControl } from "@material-ui/core";
import { FormHelperText } from "@material-ui/core";

import "./QRD2DDataSelector.css";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    // maxWidth: "36ch",
    backgroundColor: theme.palette.background.paper,
  },
  leftColumn: {
    width: 100,
  },
  blockFormTitle: {
    marginBottom: 6,
    marginTop: 20,
  },
}));

// class QRDBlocks extends React.Component {
//   render() {
const QRDBlocks = (props) => {
  const {
    blockWidth,
    finWidth,
    minHeight,
    maxHeight,
    useRounding,

    // classes, // theme
    api,
    t,
  } = props;

  const classes = useStyles();

  return (
    <div>
      <Typography variant="h5" className={classes.blockFormTitle}>
        {t("settings.blockSizes.title", "Block sizes")}
      </Typography>

      <TextField
        className={classes.leftColumn}
        id="blockWidth"
        label={t("settings.blockSizes.width", "Block width")}
        value={blockWidth}
        onChange={api.handleFloatChange("blockWidth")}
        type="number"
        inputProps={{
          min: "0",
        }}
      />

      <TextField
        className={classes.leftColumn}
        id="finWidth"
        label={t("settings.blockSizes.spacing", "Block spacing")}
        value={finWidth}
        onChange={api.handleFloatChange("finWidth")}
        type="number"
        inputProps={{
          min: "0",
        }}
      />

      <TextField
        className={classes.leftColumn}
        id="minHeight"
        label={t("settings.blockSizes.minHeight", "Min height")}
        value={minHeight}
        onChange={api.handleFloatChange("minHeight")}
        type="number"
        inputProps={{
          min: "0",
          max: maxHeight,
        }}
      />

      <TextField
        className={classes.leftColumn}
        id="maxHeight"
        label={t("settings.blockSizes.maxHeight", "Max height")}
        value={maxHeight}
        onChange={api.handleFloatChange("maxHeight")}
        type="number"
        inputProps={{
          min: "1",
        }}
      />

      <FormControl className={`${classes.formControl} ${classes.leftColumn}`}>
        <FormLabel component="legend">
          {t("settings.blockSizes.useRounding", "Use rounding")}
        </FormLabel>
        <FormGroup>
          <Switch checked={useRounding} onChange={api.handleUseRounding} />
        </FormGroup>
        <FormHelperText>
          {useRounding
            ? t("settings.blockSizes.mmSteps", "1 mm steps")
            : t("settings.blockSizes.hundredsSteps", "0.01 mm resolution")}
        </FormHelperText>
      </FormControl>
    </div>
  );
};

QRDBlocks.propTypes = {
  //  periodLength: PropTypes.number.isRequired,
  //  calculated: PropTypes.object.isRequired,
  //  primes: PropTypes.object.isRequired,

  // classes: PropTypes.object.isRequired,
  api: PropTypes.object.isRequired,
  //  setResult: PropTypes.func.isRequired,
};

//export default withStyles()(withTheme(QRDBlocks));
export default withTranslation("diffuser")(withTheme(QRDBlocks));
