/* eslint-disable react/prop-types */
import React, { useState } from "react";
import { SketchPicker } from "react-color";
import { withStyles, withTheme } from "@material-ui/core/styles";

const styles = (theme) => ({
  root: {
    width: "100%",
    // maxWidth: "36ch",
    backgroundColor: theme.palette.background.paper,
  },
  color: {
    // width: "36px",
    // height: "14px",
    width: "100%",
    height: "100%",
    borderRadius: "2px",
    //    background: `rgba(${color.r}, ${color.g}, ${color.b}, ${color.a})`,
  },
  swatch: {
    // padding: "5px",
    background: "#fff",
    borderRadius: "1px",
    // boxShadow: "0 0 0 1px rgba(0,0,0,.1)",
    boxShadow:
      "0px 1px 1px -1px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 0px 3px 0px rgba(0, 0, 0, 0.12)",
    // display: "inline-block",
    cursor: "pointer",

    width: "58px",
    height: "32px",
    display: "inline-flex",
    margin: "3px",
    padding: "7px",
    zIndex: 0,
    overflow: "hidden",
    position: "relative",
    boxSizing: "border-box",
    flexShrink: 0,
    verticalAlign: "middle",
  },
  popover: {
    position: "absolute",
    zIndex: "2",
    top: "100%",
  },
  cover: {
    position: "fixed",
    top: "0px",
    right: "0px",
    bottom: "0px",
    left: "0px",
  },
});

const ColorPicker = ({
  //   big,
  //   //color = colors.white,
  //   color = colors.blue,
  //   //backgroundColor = colors.blue,
  //   backgroundColor = colors.white,
  value,
  onChange,
  classes,
  // ...props
}) => {
  //   const sx = {
  //     fontFamily: 'inherit',
  //     fontSize: 'inherit',
  //     fontWeight: bold,
  //     textDecoration: 'none',
  //     display: 'inline-block',
  //     margin: '1px',
  //     marginBottom: big ? space[2] : space[1],
  //     paddingTop: big ? space[2] : space[1],
  //     paddingBottom: big ? space[2] : space[1],
  //     paddingLeft: space[2],
  //     paddingRight: space[2],
  //     border: 0,
  //     color,
  // //    backgroundColor,
  //     WebkitAppearance: 'none',
  //     MozAppearance: 'none'
  //   }

  const [open, setOpen] = useState(false);
  const [color, setColor] = useState(
    value
      ? value
      : {
          r: 241,
          g: 112,
          b: 19,
          a: 1,
        }
  );

  const handleClick = () => {
    setOpen(!open);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleChange = (color) => {
    setColor(color.rgb);
    if (onChange) {
      onChange({ target: { value: color.rgb } });
    }
  };

  // const { classes } = props;

  const background = `rgba(${color.r}, ${color.g}, ${color.b}, ${color.a})`;

  return (
    <>
      <div className={classes.swatch} onClick={handleClick}>
        <div
          className={classes.color}
          style={{ backgroundColor: background }}
        />
      </div>
      {open ? (
        <div className={classes.popover}>
          <div className={classes.cover} onClick={handleClose} />
          <SketchPicker color={color} onChange={handleChange} />
        </div>
      ) : null}
    </>
  );
  //   return (
  //     <ColorPicker {...props} style={sx} />
  //   )
};

// export default ColorPicker;
// export default withStyles(styles)(ColorPicker);
export default withTheme(withStyles(styles)(ColorPicker));
